/*
*   File : personal-risk-view.js
*   Author : https://evoqins.com
*   Description : Personal risk view
*   Version : 1.0.0
*/
import { Fragment } from "react";
import { CustomAmountInput, CustomTextInput, EditableSlider, RadioGroup } from "../../../Components/FormElements";
import { PrimaryButton } from "../../../Components/Buttons";

const PersonalRiskView = (props) => {

    const _handleChange = (value, question_id) => {
        props.handleChange(value, question_id);
    }

    return (
        <Fragment>
            <div className="border-bottom-1px border-bright-gray pb-2">
                <p className="e-montserrat-semi-bold e-font-16 e-line-height-28 color-dark-jungle-green mb-1 mt-3">{props.orderOne.name}</p>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-blue-gray">{props.orderOne.description}</p>
                <ol className="padding-18px-left">
                    {
                        props.orderOne.questions.map((item, key) => (
                            item.question_type === 1 ? (
                                <li key={key}
                                    className="margin-6px-bottom e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black mb-3">
                                    <p className="mb-0">{item.question}</p>
                                    <RadioGroup
                                        data={item.answers}
                                        selected={item.answer}
                                        handleSelect={(value) => _handleChange(value, item.question_id)}
                                    />
                                </li>
                            ) :
                                item.question_type === 2 ?
                                    (
                                        <li className="mb-4 e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black"
                                            key={key}>
                                            <EditableSlider label={item.question}
                                                value={item.is_answer === true ? item.answer : item.config.min_max.default}
                                                handleChange={(value) => _handleChange(value, item.question_id)}
                                                edit={true}
                                                min={item.config.min_max.min}
                                                max={item.config.min_max.max}
                                                step={item.config.step}
                                                inputPrefix={item.config.prefix_enabled === true && item.config.singular_postfix}
                                                suffix={item.config.prefix_enabled === false ? item.answer === 1 ? item.config.singular_postfix : item.config.plural_postfix : ""}
                                                singular_suffix={item.config.prefix_enabled === false && (item.config.singular_postfix)}
                                                plural_suffix={item.config.prefix_enabled === false && (item.config.plural_postfix)} />
                                        </li>
                                    )
                                    :
                                    <li className="mb-4 e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black"
                                        key={key}>
                                        {
                                            item.config.input_type === 1 ?
                                                <CustomAmountInput placeholder="80,000"
                                                    postfix="*"
                                                    error={item.error}
                                                    label={item.question}
                                                    value={item.answer ? Number(item.answer).toLocaleString("en-IN") : ""}
                                                    handleChange={(value) => _handleChange(value, item.question_id)} />
                                                :
                                                <CustomTextInput placeholder={"Type your answer"}
                                                    postfix="*"
                                                    error={item.error}
                                                    label={item.question}
                                                    value={item.answer}
                                                    handleChange={(value) => _handleChange(value, item.question_id)} />}
                                    </li>
                        ))
                    }
                </ol>
            </div>
            <div className="row justify-content-center">
                <div className="col-sm-4 col-6">
                    <PrimaryButton label="Save & continue"
                        disabled={props.loader}
                        className=" padding-12px-tb px-4 mt-4 w-100"
                        onPress={() => props.submit(2)} />
                </div>
            </div>
        </Fragment>
    );
}
export default PersonalRiskView;