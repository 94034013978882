/*
*   File : education.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Investment for education
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader, StepProgress } from "../../Components/Other";
import { CustomAmountInput, EditableSlider, RadioGroup } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { GoalInformation, GoalInvest } from "./";
import { RiskKycConfirmationModal } from "../../Components/Modal";

// Services, helper
import { _getGoal } from "../../Helper/api";
import APIService from "../../Services/api-service";

const RADIO_OPTION = [
    {
        name: "Yes",
        id: 1
    },
    {
        name: "No",
        id: 2
    }
];

const EducationGoal = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const moneyRequiredRef = useRef(null);
    const existingAmountRef = useRef(null);

    const [goalDetail, setGoalDetail] = useState({});

    const [childAge, setChildAge] = useState(0);
    const [targetAge, setTargetAge] = useState("");

    const [moneyRequired, setMoneyRequired] = useState("");
    const [moneyRequiredError, setMoneyRequiredError] = useState("");

    const [inflation, setInflation] = useState(0);

    const [existingInvestment, setExistingInvestment] = useState("");
    const [existingInvestmentError, setExistingInvestmentError] = useState("");

    const [configurationStep, setConfigurationStep] = useState(1);
    const [screenLoader, setScreenLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [moneySaved, setMoneySaved] = useState(RADIO_OPTION[1].id);
    const [investedReturn, setInvestedReturn] = useState(0);
    const [locationData, setLocationData] = useState(null);
    const [riskKycCheck, setRiskKycCheck] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            _goalDetail(location.state.goal_id);
            setConfigurationStep(location.state.step);
            if (location.state.step === 3) {
                setLocationData(location.state);
            }
        }
    }, [location]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    // API - get goal detail
    const _goalDetail = (id) => {
        _getGoal(id)
            .then((response) => {
                if (response.is_configured === false) {
                    setChildAge(response.admin_configured.meta_data.current_age.default);
                    setTargetAge(response.admin_configured.meta_data.target_age.default)
                    setInflation(response.admin_configured.meta_data.inflation_rate.default);
                    setLocationData(null);
                } else {
                    setChildAge(response.user_configured.additional_data.current_age);
                    setTargetAge(response.user_configured.tenure + response.user_configured.additional_data.current_age);
                    if (response.user_configured.additional_data.target_value) {
                        setMoneyRequired(response.user_configured.additional_data.target_value);
                    }
                    setInflation(response.user_configured.inflation_rate);
                    if (response.user_configured.additional_data.existing_investment) {
                        setExistingInvestment(response.user_configured.additional_data.existing_investment)
                        setMoneySaved(1);
                    }
                }
                if (response.user_configured?.additional_data?.pre_expected_return) {
                    setInvestedReturn(response.user_configured.additional_data.pre_expected_return);
                }
                else {
                    setInvestedReturn(response.admin_configured.meta_data.pre_expected_return.default)
                }
                setGoalDetail(response);
                setScreenLoader(false);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }


    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/milestone");
    }

    // handle childAge for dream home
    function _handleChildAge(slider_value) {
        setChildAge(slider_value);
    }

    // handle target age
    function _handleTargetAge(slider_value) {
        setTargetAge(slider_value);
    }

    // handle moneyRequired
    function _handleMoneyRequired(slider_value) {
        setMoneyRequired(slider_value);
        setMoneyRequiredError("");
    }

    // handle inflation
    function _handleInflation(slider_value) {
        setInflation(slider_value);
    }

    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    function _handleNavigateToScreen() {
        navigate("/risk-profile")
    }

    // handle current investment return
    function _handleReturnInvestment(slider_value) {
        setInvestedReturn(slider_value);
    }

    // handle existingInvestment
    function _handleExistingAmount(input_value) {
        setExistingInvestment(input_value);
        setExistingInvestmentError("");
    }

    // handle radio group
    function _handleRadioGroup(radio) {
        setMoneySaved(radio);
    }

    // handle submit
    function _submitInvestment() {
        let valid = true;
        let firstErrorRef = null;
        toast.dismiss();
        if (childAge < goalDetail.admin_configured.meta_data.current_age.min) {
            toast.error(`Child's age should be greater than or equal to ${goalDetail.admin_configured.meta_data.current_age.min} year`, {
                type: "error",
            });
            valid = false;
        } else if (targetAge < goalDetail.admin_configured.meta_data.target_age.min) {
            toast.error(`Target age should be greater than or equal to ${goalDetail.admin_configured.meta_data.target_age.min} year`, {
                type: "error",
            });
            valid = false;
        } else if (childAge >= targetAge) {
            toast.error("Investment tenure should gretaer than 1 year", {
                type: "error",
            });
            valid = false;
        }
        if (moneyRequired === "") {
            setMoneyRequiredError("Enter the required amount");
            if (!firstErrorRef) {
                firstErrorRef = moneyRequiredRef;
            }
            valid = false;
        }
        if (moneySaved === 1) {
            if (existingInvestment === "" || existingInvestment === 0) {
                setExistingInvestmentError("Enter the invested amount");
                if (!firstErrorRef) {
                    firstErrorRef = existingAmountRef;
                }
                valid = false;
            }
        }

        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            firstErrorRef.current.querySelector('input').focus();
        }

        if (valid === true) {
            setApiLoader(true);
            _setEducation();
        }
    }

    const _setEducation = () => {
        const url = "/goal/set";
        const request = {
            goal_id: location.state.goal_id,
            tenure: targetAge - childAge,
            inflation: inflation,
            target_value: moneyRequired,
            additional_data: {
                existing_investment: moneySaved === 1 ? existingInvestment : 0,
                current_age: childAge,
                pre_expected_return: investedReturn,
                target_value: moneyRequired,
            }
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setConfigurationStep(2);
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 2,
                        request_data: response.data
                    }
                });

            } else {
                if (response.data && response.data.risk_not_done === true) {
                    _confirmRiskKyc(true);
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                    });
                }
            }

            setApiLoader(false);
        })
    }

    // handle to go back previous step
    function _handleConfigurationStep(step) {
        navigate(location.pathname, {
            state: {
                goal_id: location.state.goal_id,
                step: step,
                request_data: location.state.request_data
            }
        })
    }

    return (

        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <div className="row ">
                    <div className="col-12 padding-144px-bottom">
                        <Header type={2} />
                        <div className="px-4 ">
                            <Breadcrumb mainPage="Home"
                                currentPage="Milestones"
                                childPage={goalDetail.admin_configured.name}
                                handleParentNavigate={_handleParentNavigate}
                                handleCurrentNavigate={_handleCurrentNavigate}
                                type={2} />


                            <div className="row pt-4 d-flex justify-content-center">
                                <div className="col-lg-6">
                                    {/* Step progress */}
                                    <StepProgress stepCompleted={configurationStep}
                                        gotToStep={_handleConfigurationStep} />

                                </div>

                            </div>
                            {
                                configurationStep === 1 ?
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="mt-4 e-goal-bg border-radius-16px d-flex align-items-center p-3">
                                                <img src={goalDetail.admin_configured.image}
                                                    alt="goals"
                                                    width={65}
                                                    height={54} />
                                                <div className="padding-12px-left">
                                                    <h6 className="mb-1 color-eerie-black e-font-16 e-line-height-22 e-montserrat-semi-bold">{goalDetail.admin_configured.name}</h6>
                                                    <p className="mb-0 color-dim-gray e-font-14 e-line-height-20 e-montserrat-medium">{goalDetail.admin_configured.description}</p>
                                                </div>
                                            </div>

                                            <EditableSlider label="How old is your child now?"
                                                className="mt-4"
                                                value={childAge}
                                                edit={true}
                                                handleChange={_handleChildAge}
                                                min={goalDetail.admin_configured.meta_data.current_age.min}
                                                max={goalDetail.admin_configured.meta_data.current_age.max}
                                                step={goalDetail.admin_configured.meta_data.current_age.step}
                                                suffix={childAge === 0 || childAge === 1 ? "Yr" : "Yrs"}
                                                singular_suffix={goalDetail.admin_configured.meta_data.current_age.min === 1 ? "Yr" : goalDetail.admin_configured.meta_data.current_age.min > 1 ? "Yrs" : ""}
                                                plural_suffix="Yrs" />

                                            <EditableSlider label="At what age does the child need the funds?"
                                                className="mt-4"
                                                value={targetAge}
                                                edit={true}
                                                handleChange={_handleTargetAge}
                                                min={goalDetail.admin_configured.meta_data.target_age.min}
                                                max={goalDetail.admin_configured.meta_data.target_age.max}
                                                step={goalDetail.admin_configured.meta_data.target_age.step}
                                                suffix={targetAge === 1 ? "Yr" : "Yrs"}
                                                singular_suffix={goalDetail.admin_configured.meta_data.target_age.min === 1 ? "Yr" : "Yrs"}
                                                plural_suffix="Yrs" />

                                            <div ref={moneyRequiredRef}>
                                                <CustomAmountInput
                                                    value={moneyRequired}
                                                    error={moneyRequiredError}
                                                    postfix="*"
                                                    className="mt-4"
                                                    label="What does it cost now?"
                                                    placeholder="5,00,000"
                                                    handleChange={_handleMoneyRequired} />
                                            </div>

                                            <EditableSlider label="What's the yearly inflation rate?"
                                                className="mt-4"
                                                value={inflation}
                                                handleChange={_handleInflation}
                                                min={goalDetail.admin_configured.meta_data.inflation_rate.min}
                                                max={goalDetail.admin_configured.meta_data.inflation_rate.max}
                                                step={goalDetail.admin_configured.meta_data.inflation_rate.step}
                                                suffix="%"
                                                singular_suffix="%"
                                                plural_suffix="%" />
                                            <div className="mt-4">
                                                <p className="mb-md-3 mb-0 color-eerie-black e-font-14 e-line-height-24 e-montserrat-medium">Have you started saving for their education?</p>
                                                <RadioGroup data={RADIO_OPTION}
                                                    selected={moneySaved}
                                                    handleSelect={_handleRadioGroup} />
                                            </div>
                                            {
                                                moneySaved === 1 &&
                                                <Fragment>
                                                    <div className="mt-md-4 mt-3 pb-1" ref={existingAmountRef}>
                                                        <CustomAmountInput
                                                            value={existingInvestment}
                                                            error={existingInvestmentError}
                                                            postfix="*"
                                                            label="How much you have currently saved for this purpose?"
                                                            placeholder="5,00,000"
                                                            handleChange={_handleExistingAmount} />
                                                    </div>
                                                    <EditableSlider label="What returns are you currently getting on this investment?"
                                                        className="mt-4"
                                                        value={investedReturn}
                                                        handleChange={_handleReturnInvestment}
                                                        min={goalDetail.admin_configured.meta_data.pre_expected_return.min}
                                                        max={goalDetail.admin_configured.meta_data.pre_expected_return.max}
                                                        step={goalDetail.admin_configured.meta_data.pre_expected_return.step}
                                                        suffix="%"
                                                        singular_suffix="%"
                                                        plural_suffix="%" />
                                                </Fragment>
                                            }

                                            <div className="mt-5 border-top-1px border-bright-gray mb-4"></div>

                                            <div className="d-flex justify-content-center">
                                                <div className="col-lg-6 col-md-4 col-6">
                                                    <PrimaryButton label="Calculate" className="padding-10px-tb w-100"
                                                        showLoader={apiLoader}
                                                        disabled={apiLoader}
                                                        onPress={_submitInvestment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    configurationStep === 2 ?
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-6">
                                                <GoalInformation short_description={"Recommended Corpus:"}
                                                    text1="To make your dream of educating your"
                                                    locationData={locationData}
                                                    text2="a reality, you'll need a corpus of" />
                                            </div>
                                        </div>
                                        :
                                        <div className="row ">
                                            <div className="col-xl-10 col-lg-11">
                                                <GoalInvest />
                                            </div>
                                        </div>
                            }
                        </div>

                    </div>
                </div>
                {
                    riskKycCheck === true && <RiskKycConfirmationModal
                        title="Complete Risk Profile"
                        content="Please complete your Risk Profile to start with an Investment."
                        closeModal={() => _confirmRiskKyc(false)}
                        handleConfirm={_handleNavigateToScreen} />
                }
            </div>

    )
}

export default EducationGoal;