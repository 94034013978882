/*
*   File : add-nominee.js
*   Author : https://evoqins.com
*   Description : Screen to fill the Nominees
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { differenceInYears } from "date-fns";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// import components
import { Icon } from "../../Components/Icon";
import { PrimaryButton, SecondaryButton } from "../../Components/Buttons";

import { ConfirmInvest, KYCConfirmationModal, } from "../../Components/Modal";

// services
import APIService from "../../Services/api-service";
import { _generate2Fa, _getSignupProgress } from "../../Helper/api";
import { CustomDropdown } from "../../Components/Dropdown";
import { CustomDatePicker, CustomTextInput } from "../../Components/FormElements";
import { Breadcrumb, Loader } from "../../Components/Other";
import { Header } from "../../Components/Header";
import { _getOptions } from "../../Helper/helper";


const AddNominee = () => {
    const PROGRESS_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS);
    const GENERAL_DATA = useSelector(state => state.Reducer.GENERAL_DATA);

    const nomineeRef = useRef([]);
    const navigate = useNavigate();

    const [nomineeOptions, setNomineeOptions] = useState([]);
    const [guardianOptions, setGuardianOptions] = useState([]);

    const [screenLoader, setScreenLoader] = useState(true);
    const [otpLoader, setOtpLoader] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);

    const [nomineeList, setNomineeList] = useState([
        {
            name: "",
            dob: "DD-MM-YYYY",
            allocation: 100,
            relation: null,
            guardian_name: "",
            guardian_pan: "",
            guardian_relation: null,
            error: ["", "", "", "", "", "", ""]
        }
    ]);

    const [showOtpModal, setShowOtpModal] = useState(false);
    const [modalLoader, setModalLoader] = useState(false);

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Define the disabled days rule
    const disabledDays = { after: yesterday };

    useEffect(() => {
        if (Object.keys(PROGRESS_DATA).length !== 0) {

            if (PROGRESS_DATA.kyc_data.is_nominee_available === true) {
                const nominee_list = PROGRESS_DATA.kyc_data.nominee.map((item) => {
                    return ({
                        name: item.name,
                        dob: item.dob,
                        allocation: item.allocation,
                        relation: { label: item.relation, value: item.relation_id },
                        guardian_name: item.guardian_name !== null ? item.guardian_name : "",
                        guardian_pan: item.guardian_pan !== null ? item.guardian_pan.toLocaleUpperCase() : "",
                        guardian_relation: item.guardian_relation !== null ? { label: item.guardian_relationship, value: item.guardian_relationship_id } : "",
                        error: ["", "", "", "", "", "", ""]
                    })
                });
                setNomineeList(nominee_list);
            }
        }
    }, [PROGRESS_DATA]);

    useEffect(() => {
        if (Object.keys(GENERAL_DATA).length !== 0) {
            // account types options
            const kyc_relationship = _getOptions(GENERAL_DATA.kyc_relationship);
            setNomineeOptions(kyc_relationship);

            const nominee_minor_relation = _getOptions(GENERAL_DATA.nominee_minor_relation);
            setGuardianOptions(nominee_minor_relation);
            if (kyc_relationship && nominee_minor_relation) {
                setScreenLoader(false);
            }
        }
    }, [GENERAL_DATA]);

    useEffect(() => {
        if (showSuccess === true) {
            const modal = new Modal(document.getElementById("confirm"));
            modal.show();
        }
    }, [showSuccess]);

    useEffect(() => {
        if (showOtpModal === true) {
            const modal = new Modal(document.getElementById("confirm-invest"));
            modal.show();
        }
    }, [showOtpModal]);

    // handle add nominee 
    function _handleAddNominee() {
        const nominee_list = [...nomineeList]
        nominee_list.push({
            name: "",
            dob: "DD-MM-YYYY",
            allocation: "",
            relation: null,
            guardian_name: "",
            guardian_pan: "",
            guardian_relation: null,
            error: ["", "", "", "", "", "", ""]
        });
        setNomineeList(nominee_list);
    }

    // handle remove nominee 
    function _handleRemoveNominee(index) {
        const nominee_list = [...nomineeList]
        nominee_list.splice(index, 1)
        setNomineeList(nominee_list);
    }

    // handle nominee values 
    function _handleNominee(item_value, key, index, error_index) {
        const nominee_list = [...nomineeList]
        nominee_list[index][key] = item_value;
        nominee_list[index]['error'][error_index] = "";
        setNomineeList(nominee_list);
    }

    // handle confirm investment
    function _confirmNominee(status) {
        setShowSuccess(status);
    }


    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/nominee");
    }

    function _handleDate(date) {
        const [day, month, year] = date.split('-');
        const start_date = new Date(`${year}-${month}-${day}`);
        return start_date
    }

    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return new Date(year, month - 1, day);
    };

    // handle validate nominees
    function _validateNominees() {
        let valid = true;
        const validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN
        let is_scrolled = false;

        const nominee_list = [...nomineeList]
        for (let i = 0; i < nominee_list.length; i++) {

            const element = nominee_list[i];
            const IS_MINOR = differenceInYears(new Date(), new Date(element.dob)) < 18;

            if (element.name === "") {
                nominee_list[i].error[0] = "Nominee name is required"
                valid = false
                if (!is_scrolled) {
                    nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                    is_scrolled = true;
                }
            }
            if (element.relation === null) {
                nominee_list[i].error[1] = "Relation is required"
                valid = false
                if (!is_scrolled) {
                    nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                    is_scrolled = true;
                }
            }
            if (element.dob === "DD-MM-YYYY") {
                nominee_list[i].error[2] = "Date of Birth is required"
                valid = false
                if (!is_scrolled) {
                    nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                    is_scrolled = true;
                }
            }
            if (element.allocation === "" || element.allocation < 1) {
                nominee_list[i].error[3] = "Allocation is required"
                valid = false
                if (!is_scrolled) {
                    nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                    is_scrolled = true;
                }
            }
            if (IS_MINOR === true && element.guardian_name === "") {
                nominee_list[i].error[4] = "Guardian name is required"
                valid = false
                if (!is_scrolled) {
                    nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                    is_scrolled = true;
                }
            }
            if (IS_MINOR === true && element.guardian_pan === "") {
                nominee_list[i].error[5] = "Guardian PAN number is required"
                valid = false
                if (!is_scrolled) {
                    nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                    is_scrolled = true;
                }
            }
            if (IS_MINOR === true && element.guardian_pan.toLocaleUpperCase() !== "" && !validate_pan.test(element.guardian_pan.toLocaleUpperCase())) {
                nominee_list[i].error[5] = "Invalid PAN number"
                valid = false;
                if (!is_scrolled) {
                    nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                    is_scrolled = true;
                }
            }
            if (IS_MINOR === true && element.guardian_relation === null) {
                nominee_list[i].error[6] = "Choose relation with nominee (guardian relation)"
                valid = false
                if (!is_scrolled) {
                    nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                    is_scrolled = true;
                }
            }

        }


        setNomineeList(nominee_list);

        if (valid === true) {
            const sum = nominee_list.reduce((a, b) => parseInt(a) + parseInt(b.allocation), 0);


            if (sum !== 100) {
                toast.error("Total alllocation should be 100%", {
                    type: "error"
                });
                valid = false;
            } else {
                _generate2FaApi();
            }
        }
    }

    // API - otp
    const _generate2FaApi = () => {
        setOtpLoader(true);
        _generate2Fa("Nominee")
            .then((response) => {
                setShowOtpModal(true);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
        setOtpLoader(false)
    };


    // API -save nominee
    const _saveNominee = async (otp) => {
        setModalLoader(true);
        var nominee_data = [];

        toast.dismiss();

        const url = "/profile/upsert-nominee";


        nominee_data = nomineeList.map((item) => {
            const MINOR = differenceInYears(new Date(), new Date(item.dob)) < 18;
            return ({
                name: item.name,
                relation_id: item.relation.value,
                dob: item.dob,
                guardian_name: MINOR === true ? item.guardian_name : null,
                guardian_relationship_id: MINOR === true ? item.guardian_relation.value : null,
                guardian_pan: MINOR === true ? item.guardian_pan : null,
                allocation: item.allocation
            })
        })

        const request = {
            "nominee": nominee_data,
            "is_nominee_available": true,
            "otp": otp
        }

        try {
            const response = await APIService(true, url, request);
            if (response.status_code === 200) {
                _getSignupProgress().then((response) => {
                    _confirmNominee(true);
                })
                return true;
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                setModalLoader(false);
                return false;
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setModalLoader(false);
            return false;
        }
    };


    return (
        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    <div className="row ">
                        <div className="col-12 padding-144px-bottom">
                            <Header type={2} />
                            <div className="px-4 ">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Manage Nominee"
                                    childPage={"Nominee Details"}
                                    handleParentNavigate={_handleParentNavigate}
                                    handleCurrentNavigate={_handleCurrentNavigate}
                                    type={2} />
                                <p className="e-montserrat-semi-bold e-font-18 e-line-height-38 color-dark-jungle-green margin-30px-top mb-0">Nominee Details</p>
                                <div className="row d-flex justify-content-center">

                                    <div className="col-lg-6">

                                        <Fragment>
                                            {
                                                nomineeList.map((item, index) => {
                                                    return (
                                                        <div ref={el => nomineeRef.current[index] = el}
                                                            key={index}>
                                                            <div className="d-flex justify-content-between align-items-center my-4">
                                                                <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0">Nominee-{index + 1}</p>
                                                                {
                                                                    nomineeList.length > 1 &&
                                                                    <Icon icon="delete"
                                                                        size={24}
                                                                        className="cursor-pointer"
                                                                        onClick={() => _handleRemoveNominee(index)} />
                                                                }
                                                            </div>

                                                            <div className="mb-4">
                                                                <CustomTextInput label="Nominee holder name"
                                                                    postfix="*"
                                                                    value={item.name}
                                                                    error={item.error[0]}
                                                                    placeholder="Nominee name"
                                                                    handleChange={(input_value) => _handleNominee(input_value, 'name', index, 0)} />
                                                            </div>


                                                            <div className="mb-4">
                                                                <CustomDropdown label="Nominee relationship"
                                                                    postfix="*"
                                                                    options={nomineeOptions}
                                                                    value={item.relation}
                                                                    error={item.error[1]}
                                                                    message="No nominee found"
                                                                    placeholder="Relation with nominee"
                                                                    onSelectChange={(select_value) => _handleNominee(select_value, 'relation', index, 1)} />
                                                            </div>


                                                            <div className="mb-4">

                                                                <CustomDatePicker error={item.error[2]}
                                                                    disabledDays={disabledDays}
                                                                    label="Date of birth"
                                                                    postfix={true}
                                                                    start={item.dob}
                                                                    defaultMonth={item.dob !== "DD-MM-YYYY" ? _handleDate(item.dob) : null}
                                                                    mode="single"
                                                                    selectDate={(date) => {
                                                                        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                                                                        const start_date = new Date(date);
                                                                        const formatted_start_date = start_date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
                                                                        _handleNominee(formatted_start_date, 'dob', index, 2)

                                                                    }} />
                                                            </div>

                                                            <div className="mb-4">

                                                                <CustomTextInput label="Allocation"
                                                                    postfix="*"
                                                                    value={item.allocation}
                                                                    error={item.error[3]}
                                                                    type="number"
                                                                    placeholder="Allocation %"
                                                                    handleChange={(input_value) => {
                                                                        if (input_value <= 100) {
                                                                            _handleNominee(input_value, 'allocation', index, 3)
                                                                        }
                                                                    }} />
                                                            </div>
                                                            {differenceInYears(new Date(), new Date(parseDate(item.dob))) < 18 && <Fragment>
                                                                <div className="mb-4">

                                                                    <CustomTextInput label="Guardian name"
                                                                        postfix="*"
                                                                        value={item.guardian_name}
                                                                        error={item.error[4]}
                                                                        placeholder="Name"
                                                                        handleChange={(input_value) => {
                                                                            _handleNominee(input_value, 'guardian_name', index, 4)
                                                                        }} />
                                                                </div>

                                                                <div className="mb-4">
                                                                    <CustomDropdown label="Guardian relationship"
                                                                        postfix="*"
                                                                        options={guardianOptions}
                                                                        value={item.guardian_relation}
                                                                        error={item.error[6]}
                                                                        placeholder="Guardian relationship"
                                                                        message="No guardian found"
                                                                        onSelectChange={(select_value) => _handleNominee(select_value, 'guardian_relation', index, 6)} />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <CustomTextInput label="Guardian PAN Number"
                                                                        postfix="*"
                                                                        maxLength={10}
                                                                        value={item.guardian_pan}
                                                                        error={item.error[5]}
                                                                        placeholder="PAN"
                                                                        inputClass="text-transform-upper"
                                                                        handleChange={(input_value) => _handleNominee(input_value, 'guardian_pan', index, 5)} />
                                                                </div>
                                                            </Fragment>}
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div className="d-flex align-items-center gap-4px e-bg-light-lavender padding-10px-tb px-2 border-radius-44px e-font-14 e-line-height-18 e-montserrat-medium w-max-content color-rebecca-purple cursor-pointer" onClick={_handleAddNominee}>
                                                <Icon icon="add"
                                                    size={24} />
                                                Add another nominee
                                            </div>
                                            <div className="d-flex gap-8px e-bg-papaya-whip mt-4 border-radius-12px padding-14px-all">
                                                <Icon icon="info-circle-yellow"
                                                    size={20}
                                                />
                                                <p className="e-montserrat-medium e-font-14 e-line-height-20 color-vivid-orange mb-0">Adding a nominee ensures your chosen person will receive your assets, simplifying the transfer process when needed.</p>
                                            </div>
                                        </Fragment>
                                        <div className="border-top-1px border-bright-gray my-md-4 my-3" />
                                        <div className="row">
                                            <div className="col-6">
                                                <SecondaryButton label="Cancel"
                                                    className="padding-12px-tb w-100"
                                                    onPress={_handleCurrentNavigate} />
                                            </div>
                                            <div className="col-6">
                                                <PrimaryButton label="Confirm"
                                                    className="padding-12px-tb w-100"
                                                    showLoader={otpLoader}
                                                    disabled={otpLoader}
                                                    onPress={_validateNominees} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showOtpModal === true &&
                    <ConfirmInvest closeModal={() => setShowOtpModal(false)}
                        verify={_saveNominee}
                        loader={modalLoader} />
                }

                {
                    showSuccess &&
                    <KYCConfirmationModal title={"Nominee Added Successful"}
                        type={1}
                        show_button={true}
                        content=""
                        closeModal={() => _confirmNominee(false)}
                        handleNavigate={_handleCurrentNavigate} />
                }

            </Fragment >
    )
}
export default AddNominee