/*
*   File : goal-information.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Information 
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

// import components
import { Icon } from "../../Components/Icon";
import { PrimaryButton } from "../../Components/Buttons";
import { CustomAmountInput } from "../../Components/FormElements";

import APIService from "../../Services/api-service";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../Components/Other";

// Custom investment type
const InvestmentType = (props) => {
    return (
        <div className={`p-3 border-1px  cursor-pointer border-radius-12px w-100 margin-14px-bottom ${props.selectedValue === props.type ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`}
            onClick={() => props.handleClick(props.type)}>
            <div className="d-flex justify-content-between align-items-start gap-24px">
                <div>
                    <div className="d-flex gap-8px align-items-center">
                        <img src={props.icon} alt={props.label}
                            draggable={false}
                            width={32}
                            height={32} />
                        {props.heading}

                    </div>
                </div>

                <Icon icon={
                    props.selectedValue === props.type
                        ?
                        'radio-checked' :
                        'radio-unchecked'
                }
                    size={24} />
            </div>
            {
                props.description &&
                <p className="color-eerie-black e-font-12 e-line-height-24 e-montserrat-medium mb-0 mt-2"> {props.description}</p>
            }

        </div>
    );
};


const GoalInformation = ({ text1, text2, locationData = null }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [goalDetail, setGoalDetail] = useState({});
    const [selectedValue, setSelectedValue] = useState(1);
    const [isSipChecked, setIsSipChecked] = useState(false);
    const [sipAmount, setSipAmount] = useState("");
    const [isLumpsumChecked, setIsLumpsumChecked] = useState(false);
    const [lumpsumAmount, setLumpsumAmount] = useState("");
    const [downfallAmount, setDownfallAmount] = useState("");
    const [loader, setLoader] = useState(true);
    const [amountError, setAmountError] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (location.state !== null) {
            setGoalDetail(location.state.request_data);
            setLoader(false);
            if (location.state.request_data.goal_type_id === 14) {
                setSelectedValue(3)
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (locationData !== null) {
            if (locationData.custom !== null) {
                setSelectedValue(4);
                setIsSipChecked(locationData.custom.isSipChecked)
                setIsLumpsumChecked(locationData.custom.isLumpsumChecked);
                setSipAmount(locationData.custom.sipAmount);
                setLumpsumAmount(locationData.custom.lumpsumAmount)
            } else {
                if (locationData.investment_option === "sip_and_lumpsum") {
                    setSelectedValue(1);
                } else if (locationData.investment_option === "sip_only") {
                    setSelectedValue(2);
                } else {
                    setSelectedValue(3);
                }
            }
        }
    }, [locationData])

    useEffect(() => {
        if (sipAmount.length !== 0 || lumpsumAmount.length !== 0) {
            _calculateCustomAmount();
        }
        else {
            setDownfallAmount("");
        }
        // eslint-disable-next-line
    }, [sipAmount, lumpsumAmount]);

    // handle Investment type
    function _handleInvestmentType(id) {
        setSelectedValue(id);
    }

    const _handleInputChange = (type, value) => {
        setAmountError("");
        switch (type) {
            case 1:
                setSipAmount(value);
                break;
            case 2:
                setLumpsumAmount(value);
                break;
            default:
                break;
        }
    }

    const _handleSubmit = () => {
        let valid = true;
        if (selectedValue === 4) {
            if (sipAmount.length === 0 && lumpsumAmount.length === 0) {
                setAmountError("Enter sip amount or lumpsum amount");
                valid = false;
            }
        }
        if (valid === true) {
            _getInvestmentSuggestions();
        }
    }

    const _handleCheckbox = (type) => {
        switch (type) {
            case 1:
                setIsSipChecked(!isSipChecked);
                if (isSipChecked === true) {
                    setSipAmount("");
                }
                break;
            case 2:
                setIsLumpsumChecked(!isLumpsumChecked);
                if (isLumpsumChecked === true) {
                    setLumpsumAmount("");
                }
                break;

            default:
                break;
        }
    }

    // API - Get Investment Suggestions
    const _getInvestmentSuggestions = () => {
        let investment_type = ['sip_and_lumpsum', 'sip_only', 'lumpsum_only', 'custom']; // possible values for investment type

        const url = "/goal/investment-suggestion";
        const request = {
            goal_id: location.state.goal_id,
            sip: selectedValue === 4 ? sipAmount.length === 0 ? null : sipAmount : null,
            lumpsum: selectedValue === 4 ? lumpsumAmount.length === 0 ? null : lumpsumAmount : null,
            investment_option: investment_type[selectedValue - 1]
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 3,
                        request_data: location.state.request_data,
                        custom: selectedValue === 4 ? {
                            isSipChecked: isSipChecked,
                            isLumpsumChecked: isLumpsumChecked,
                            sipAmount: sipAmount,
                            lumpsumAmount: lumpsumAmount
                        } : null,
                        investment_option: isSipChecked && !isLumpsumChecked ? "sip_only" : !isSipChecked && isLumpsumChecked ? "lumpsum_only" : isSipChecked && isLumpsumChecked ? "sip_and_lumpsum" : investment_type[selectedValue - 1]
                    }
                });

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        })
    }

    // API - calculate custom amounts
    const _calculateCustomAmount = () => {
        const url = "/goal/check-custom-amount";
        const request = {
            goal_id: location.state.goal_id,
            sip: parseInt(sipAmount, 10),
            lumpsum: parseInt(lumpsumAmount, 10)
        };

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                if (response.data.downfall === false) {
                    setDownfallAmount("");
                } else {
                    setDownfallAmount(response.data.downfall_amount_text)
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        });
    }

    return (
        <>
            {
                loader === true ?
                    <Loader height="h-80vh" />

                    :
                    <Fragment>

                        <div className="mt-4 e-goal-summary border-radius-16px p-3">
                            <div className="margin-12px-bottom">
                                <h6 className="mb-1 color-eerie-black e-font-16 e-line-height-22 e-montserrat-medium">{text1} <span className="e-montserrat-semi-bold">"{goalDetail.name}"</span> {text2}</h6>
                                {/* <p className="mb-0 color-dim-gray e-font-14 e-line-height-20 e-montserrat-medium">{goalDetail.description}</p> */}
                            </div>
                            {/* <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-2">{short_description}</p> */}
                            <div className="color-dim-gray e-montserrat-regular e-font-14 e-line-height-20"><span className="color-green e-font-24 e-montserrat-bold e-line-height-32">{goalDetail.corpus_amount_indian_format}</span> {(goalDetail.goal_type_id !== 6 && goalDetail.goal_type_id !== 10 && goalDetail.goal_type_id !== 13) && <div className="color-dim-gray e-montserrat-regular e-font-12 e-line-height-20 mt-2">*{goalDetail.based_on_text}</div>}</div>
                        </div>

                        <div className="d-flex align-items-start padding-12px-all mt-4 e-bg-ivory-bridge border-radius-12px  color-vivid-orange e-font-12 e-line-height-20 gap-8px e-montserrat-medium">
                            <span>
                                <Icon icon="info-circle-yellow"
                                    size={16} />
                            </span>
                            The corpus calculation and fund suggestions rely on past performance average of selected funds, which may not predict future results.
                        </div>


                        {
                            goalDetail.goal_type_id === 14 &&
                            <div className="d-flex align-items-start padding-12px-all mt-3 e-bg-light-blue border-radius-12px color-ocean-blue e-font-12 e-line-height-20 gap-8px e-montserrat-medium">
                                <span>
                                    <Icon icon="info-circle"
                                        size={16} />
                                </span>
                                The approximate tax liability for this investment could be between 3-7%, offering considerable tax advantages over traditional instruments
                            </div>
                        }


                        {/* {
                            goalDetail.goal_type_id !== 14 ? */}
                        <h6 className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black my-4">Here are your options to achieve your milestone.</h6>
                        {/* :
                                <h6 className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black my-4">Here are the 2 Great Options For you to achieve your milestone?</h6>
                        } */}


                        {/* Investment types */}
                        {
                            goalDetail.goal_type_id !== 14 &&
                            <Fragment>
                                {/* SIP and Lumpsum */}
                                <InvestmentType handleClick={_handleInvestmentType}
                                    type={1}
                                    selectedValue={selectedValue}
                                    heading={
                                        <p className="e-montserrat-medium e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                            Lumpsum + SIP <span className="e-font-16 e-montserrat-semi-bold">({goalDetail.sip_and_lumpsum.lumpsum_text} + {goalDetail.sip_and_lumpsum.sip_text})</span>
                                        </p>
                                    }
                                    icon={require("../../Assets/Images/Goal/sip-lumpsum.png")} />


                                {/* SIP */}
                                <InvestmentType handleClick={_handleInvestmentType}
                                    type={2}
                                    selectedValue={selectedValue}
                                    heading={<p className="e-montserrat-medium e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                        SIP only <span className="e-font-16 e-montserrat-semi-bold">({goalDetail.sip_only.sip_text})</span>
                                    </p>}
                                    icon={require("../../Assets/Images/Goal/sip.png")}
                                // description="Since you are not putting any lumpsum the SIP amount increased." 
                                />
                            </Fragment>
                        }

                        {/* Lumpsum */}
                        <InvestmentType handleClick={_handleInvestmentType}
                            type={3}
                            selectedValue={selectedValue}
                            heading={<p className="e-montserrat-medium e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                Lumpsum only <span className="e-font-16 e-montserrat-semi-bold">({goalDetail.lumpsum_only.lumpsum_text})</span></p>}
                            icon={require("../../Assets/Images/Goal/lumpsum.png")}
                        />



                        <p className="my-3 e-font-14 e-line-height-22 e-montserrat-semi-bold color-davy-gray text-center">Or</p>

                        {/* custom investment  */}
                        <div className={`p-3 border-1px  cursor-pointer border-radius-12px w-100 margin-14px-bottom ${selectedValue === 4 ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`}
                            onClick={() => _handleInvestmentType(4)}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex gap-8px align-items-center">
                                    <img src={require("../../Assets/Images/Goal/custom-sip.png")}
                                        alt="Custom amount"
                                        draggable={false}
                                        width={32}
                                        height={32} />
                                    <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-0">I want to put a custom amount</p>
                                </div>
                                <Icon icon={selectedValue === 4 ? 'radio-checked' : 'radio-unchecked'}
                                    size={24} />
                            </div>

                            {
                                selectedValue === 4 &&
                                <Fragment>
                                    {/* SIP and Lumpsum input */}
                                    <div className="row my-3">
                                        {
                                            goalDetail.goal_type_id !== 14 &&
                                            <div className="col-sm-6">
                                                <div className="d-flex align-items-center gap-8px mb-3">
                                                    <Icon icon={isSipChecked ? "checked" : "unchecked"}
                                                        size={24}
                                                        onClick={() => _handleCheckbox(1)} />
                                                    <span className="e-montserrat-medium e-font-14 e-line-height-20 color-black">SIP</span>
                                                </div>
                                                <CustomAmountInput placeholder="1,00,000"
                                                    error={amountError}
                                                    disabled={!isSipChecked}
                                                    value={sipAmount}
                                                    handleChange={(value) => _handleInputChange(1, value)} />
                                            </div>
                                        }

                                        <div className="col-sm-6">
                                            <div className="d-flex align-items-center gap-8px mb-3">
                                                <Icon icon={isLumpsumChecked ? "checked" : "unchecked"}
                                                    size={24}
                                                    onClick={() => _handleCheckbox(2)} />
                                                <span className="e-montserrat-medium e-font-14 e-line-height-20 color-black">Lumpsum</span>
                                            </div>
                                            <CustomAmountInput
                                                placeholder="3,00,000"
                                                value={lumpsumAmount}
                                                disabled={!isLumpsumChecked}
                                                handleChange={(value) => _handleInputChange(2, value)} />
                                        </div>
                                    </div>

                                    {/*  */}
                                    {
                                        downfallAmount.length !== 0 &&
                                        <div className="padding-12px-all e-bg-misty-rose border-radius-12px d-flex gap-8px mt-3">
                                            <img src={require("../../Assets/Images/Goal/red-info.png")}
                                                alt="Red info"
                                                width={20}
                                                height={20} />
                                            <p className="e-montserrat-medium e-font-14 e-line-height-24 color-deep-carmine-pink mb-0">Investing a lower amount than the recommended one may result in a shortfall of <span className="e-montserrat-bold">{downfallAmount}</span>.
                                                This might push your goal to longer than the expected tenure.</p>
                                        </div>
                                    }
                                </Fragment>
                            }
                        </div>

                        <div className="my-4 border-top-1px border-bright-gray "></div>

                        {/* Save investment amount and type  */}
                        <div className="d-flex justify-content-center">
                            <div className="col-lg-6 col-md-4 col-6">
                                <PrimaryButton label="Continue"
                                    className="padding-10px-tb w-100"
                                    onPress={_handleSubmit} />
                            </div>
                        </div>

                    </Fragment>
            }
        </>
    )
}

export default GoalInformation