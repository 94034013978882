/*
 *   File : area.js
 *   Author URI : https://evoqins.com
 *   Description : Area chart to show portfolio returns
 *   Integrations : chart.js
 *   Version : v1.1
 */
import { Fragment, useEffect, useState, useRef } from "react";
import Chart from "chart.js/auto";

import Colors from '../../Styles/color.module.scss';
import style from "../../Styles/Components/area.module.scss";

const AreaChart = ({ data, filters, tenure, setYear, dataset1, dataset2, borderColor1, labels }) => {

    const chartRef = useRef(null);
    // const [chart, setChart] = useState(null);
    // const [focusPoint, setFocusPoint] = useState(null);

    const [year, setYearState] = useState(tenure);

    // eslint-disable-next-line

    useEffect(() => {
        setYearState(tenure);
    }, [tenure]);


    useEffect(() => {
        if (data.labels !== undefined) {
            var chartContext = document.getElementById('areaChart').getContext('2d');
            chartContext.canvas.style.zIndex = 1;


            const chartInstance = new Chart(chartContext, {
                type: 'line',
                data: {
                    labels: labels || [],
                    datasets: [
                        {
                            data: dataset1 || [],
                            order: 1,
                            backgroundColor: "transparent",
                            borderColor: borderColor1,
                            borderWidth: 1.5,
                            tension: 0.5,
                            fill: 'start',
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            pointBorderWidth: 0,
                            pointHoverBorderWidth: 0,
                            pointHoverBorderColor: Colors.white,
                            pointBorderColor: Colors.white,
                            pointBackgroundColor: Colors.rebecca_purple,
                        },
                        dataset2.length > 0 && {
                            data: dataset2 || [],
                            order: 1,
                            backgroundColor: "transparent",
                            borderColor: Colors.sunglow,
                            borderWidth: 1.5,
                            tension: 0.5,
                            fill: 'start',
                            pointRadius: 0,
                            pointHoverRadius: 0,
                            pointBorderWidth: 0,
                            pointHoverBorderWidth: 0,
                            pointHoverBorderColor: Colors.white,
                            pointBorderColor: Colors.white,
                            pointBackgroundColor: Colors.charleston_green,
                        }
                    ].filter(Boolean)
                },

                options: {
                    layout: {
                        padding: {
                            top: 20,
                            bottom: 20,
                            left: 20,
                            right: 20
                        }
                    },
                    indexAxis: 'x',
                    scales: {
                        x: {
                            beginAtZero: false,
                            offset: false,
                            border: {
                                display: false,
                            },
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false
                            },
                        },
                        y: {
                            beginAtZero: false,
                            offset: false,
                            ticks: {
                                display: false,
                                maxTicksLimit: 5,
                                stepSize: 2,
                            },
                            border: {
                                display: false,
                            },
                            grid: {
                                borderColor: Colors.anti_flash_white,
                            },
                        },
                    },
                    interaction: {
                        intersect: false,
                        mode: 'index',
                    },
                    plugins: {
                        tooltip: {
                            enabled: true,
                            backgroundColor: Colors.white,
                            borderColor: Colors.bright_gray,
                            borderWidth: 1,
                            titleFont: {
                                size: 0,
                            },
                            bodyFont: {
                                family: "Montserrat-Medium",
                                size: 12,
                            },
                            footerColor: Colors.dark_silver,
                            bodyColor: Colors.black,
                            callbacks: {
                                title: () => '',
                                label: (context) => {
                                    const value = context.raw;
                                    return `NAV : ₹${value} (${context.label})`
                                },
                            },
                            position: 'nearest', // Position tooltip closer to the active point
                            caretSize: 6,
                            caretPadding: 10,
                            cornerRadius: 4,
                            padding: 10,
                            boxPadding: 4,
                            usePointStyle: true
                        },
                        legend: {
                            display: false,
                        },
                    }
                },
                plugins: [{
                    id: 'mouseLine',
                    afterDatasetsDraw: (chart) => {
                        if (chart.tooltip._active && chart.tooltip._active.length && chart.tooltip) {

                            const ctx = chart.ctx;
                            const activePoint = chart.tooltip._active[0];
                            const x = activePoint.element.x;
                            const y = activePoint.element.y;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            chartRef.current = activePoint;

                            // Draw vertical dotted line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, bottomY);
                            ctx.lineTo(x, topY);
                            ctx.setLineDash([5, 5]); // Dotted line style
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = Colors.rebecca_purple;
                            ctx.stroke();
                            ctx.restore();

                            // Draw the pointer (circle) at the active point
                            ctx.save();
                            ctx.beginPath();
                            ctx.arc(x, y, 7, 0, 2 * Math.PI); // Pointer size
                            ctx.fillStyle = Colors.rebecca_purple; // Pointer color
                            ctx.fill();
                            ctx.lineWidth = 2;
                            ctx.strokeStyle = Colors.white; // Pointer border color
                            ctx.stroke();
                            ctx.restore();
                        }
                    },
                    beforeRender: (chart) => {
                        const { tooltip, data } = chart;

                        // If there's no data, exit
                        if (!data.datasets.length) {
                            return;
                        }
                        // Ensure chartRef and the element exist
                        if ((!tooltip._active || !tooltip._active.length)) {
                            setTimeout(() => {
                                if (chartRef.current && chartRef.current.element && chartRef.current.element.x) {
                                    chart.tooltip.setActiveElements(
                                        [{ datasetIndex: chartRef.current.datasetIndex, index: chartRef.current.index }],
                                        { x: chartRef.current.element.x, y: chartRef.current.element.y }
                                    );
                                    chart.update(); // Update the chart to show the tooltip
                                }
                            }, 50)
                        }

                    },

                    afterRender: (chart) => {
                        // Get the first dataset
                        const { tooltip, data } = chart;

                        // If there's no data, exit
                        if (!data.datasets.length) {
                            return;
                        }

                        // Show tooltip at the first point if not visible
                        if ((!tooltip._active || !tooltip._active.length)) {
                            if (chartRef.current) {
                                chart.tooltip.setActiveElements(
                                    [{ datasetIndex: chartRef.current.datasetIndex, index: chartRef.current.index }],
                                    { x: chartRef.current.element.x, y: chartRef.current.element.y }
                                );
                                chart.update(); // Update the chart to show the tooltip
                            }
                        }
                    },
                }]
            });
            // Function to show tooltip on the last point
            function showTooltipOnLastPoint() {
                const chart = chartInstance;


                const lastIndex = chart.data.labels.length - 1; // Last index of data
                const meta = chart.getDatasetMeta(0); // Get metadata for the dataset
                const lastPoint = meta.data[lastIndex]; // Get the last point

                chart.tooltip.setActiveElements(
                    [{ datasetIndex: 0, index: lastIndex }], // Set active element to the last point
                    { x: lastPoint.x, y: lastPoint.y, caretX: lastPoint.x, caretY: lastPoint.y } // Position for the tooltip
                );

                chart.update(); // Update the chart to show the tooltip
            }


            showTooltipOnLastPoint();

            return () => {
                chartInstance.destroy();
            };
        }
        // eslint-disable-next-line
    }, [data, dataset1, dataset2]);

    const _handleYear = (id) => {
        setYear(id);
    }

    return (
        <Fragment>
            <div className="position-relative" id='areaChartContainer'>
                <canvas id="areaChart" className="position-relative" height="110" width="400" style={{ width: "100%" }}></canvas>
                {
                    dataset1.length === 0 &&
                    <div className="e-goal-chart-height d-flex justify-content-center align-items-center color-chinese-black e-montserrat-regular e-font-16 e-line-height-22 position-absolute top-0 end-0 bottom-0 start-0 m-auto">
                        No data found
                    </div>
                }
            </div>
            <div className="d-flex justify-content-center w-100 mt-3">
                <div className="d-flex gap-16px p-1 e-bg-cultured border-radius-16px white-space-nowrap overflow-x-auto">
                    {
                        filters.map((item, key) =>
                            <span key={key}
                                className={`e-font-16 e-line-height-22 padding-6px-lr padding-1px-tb w-fit-content cursor-pointer padding-6px-tb padding-12px-lr e-fund-filter
                    ${year === item.filter_type ? `e-montserrat-semi-bold color-rebecca-purple border-1px border-platinum bg-white border-radius-8px ${style.e_year}` : "color-chinese-black e-montserrat-regular"}`}
                                onClick={() => year !== item.filter_type && _handleYear(item.filter_type)}>
                                {item.period}
                            </span>
                        )
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default AreaChart;

AreaChart.defaultProps = {
    data: {
        labels: []
    },
    dataset1: [],
    dataset2: [],
    filters: [],
    tenure: '',
    setYear: () => { }
};
