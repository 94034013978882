/*
*   File : faq.js
*   Author : https://evoqins.com
*   Description : Screen for faq
*   Version : 1.0.0
*/

import { Fragment, useEffect, useState } from "react"
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { Header } from "../../Components/Header";
import { Breadcrumb, EmptyScreen, Loader } from "../../Components/Other";
import APIService from "../../Services/api-service";
import { Icon } from "../../Components/Icon";

import Color from "../../Styles/color.module.scss";
import { PrimaryButton, SecondaryButton } from "../../Components/Buttons";
import { CustomTabBar } from "../../Components/Tab";
import { CustomHeader } from "../../Components/Navigation";

const FAQ = (props) => {

    const navigate = useNavigate();
    const [apiLoader, setApiLoader] = useState(true);
    const [faqCategory, setFaqCategory] = useState([]);
    const [faqTypes, setFaqTypes] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [faqList, setFaqList] = useState([]);
    const [listLoader, setListLoader] = useState(true);
    const [search, setSearch] = useState("");

    useEffect(() => {
        _getFAQTypes();
    }, []);

    useEffect(() => {
        if (activeTab !== null) {
            _getFAQList();
        }
        //eslint-disable-next-line
    }, [activeTab]);

    function _handleNavigate() {
        navigate("/");
    }

    const _handleTab = (item) => {
        setActiveTab(item);
        setListLoader(true);
        setFaqList([]);
    }

    const _handleSearch = (event) => {
        setSearch(event.target.value)
    }

    const _handleMyTickets = (tab) => {
        if (props.type === 1) {
            navigate("/");
        }
        else {
            navigate('/support', { state: { tab_index: tab, current_page: 1, } });
        }
    }

    // API - FAQ types
    const _getFAQTypes = () => {
        const url = "/faq/faq-types";

        APIService(false, url).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setFaqCategory(response.data);
                const faq_types = response.data.map((item) => {
                    return ({
                        label: item.name,
                        id: item.faq_type_id,
                        icon: item.image
                    })
                })
                setFaqTypes(faq_types);
                setActiveTab(response.data[0]);
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }

    // API - FAQ list
    const _getFAQList = () => {
        const url = "/faq/get-faq"
        const request = {
            "faq_type_id": activeTab.faq_type_id,
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setFaqList(response.data);
            }
        })
        setListLoader(false);
    }


    return (
        apiLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className={props.type === 1 ? "bg-white" : ""}>
                    <div className={props.type === 1 ? "container" : "bg-white border-radius-tl-40px border-radius-bl-40px"}>
                        {/* Header */}
                        {props.type === 1 ? <CustomHeader /> :
                            <Header type={2} />}
                        <div className="row ">
                            <div className={props.type === 1 ? "col-lg-12" : "col-lg-11 pe-lg-0 padding-144px-bottom"}>

                                <div className="px-4">
                                    {props.type === 1 ? null : <Breadcrumb mainPage="Home"
                                        currentPage="FAQs"
                                        handleNavigate={_handleNavigate}
                                        type={1} />}
                                    <div className="row margin-30px-top">
                                        <div className="col-xl-3 col-lg-4">
                                            <p className="e-montserrat-semi-bold e-font-18 e-line-height-38 mb-lg-4  mb-0 color-dark-jungle-green">FAQ’s</p>
                                            <div className="e-profile-border border-radius-24px d-lg-block d-none">
                                                {
                                                    faqCategory.map((item, key) =>
                                                        <div className={`${activeTab.faq_type_id === item.faq_type_id ? "e-bg-light-lavender" : "cursor-pointer"} 
                                                        ${key === 0 && "border-radius-tlr-24px "} 
                                                        ${key !== faqCategory.length - 1 && "border-bottom-1px border-chinese-white"}
                                                        ${key === faqCategory.length - 1 && "border-radius-blr-24px"}
                                                        px-4 padding-22px-tb `}
                                                            key={key}
                                                            onClick={() => {
                                                                if (activeTab.faq_type_id !== item.faq_type_id) {
                                                                    _handleTab(item)
                                                                }
                                                            }}>
                                                            <div className="d-flex justify-content-between align-items-start">
                                                                <div className="d-flex gap-8px">
                                                                    <img src={item.image}
                                                                        width={24}
                                                                        alt="category"
                                                                        height={24} />
                                                                    <div>
                                                                        <p className={`e-montserrat-semi-bold e-font-16 e-line-height-20 mb-2 ${activeTab.faq_type_id === item.faq_type_id ? "color-rebecca-purple" : "color-eerie-black"}`}>{item.name}</p>
                                                                        <p className={`e-montserrat-regular e-font-14 e-line-height-20 mb-0 ${activeTab.faq_type_id === item.faq_type_id ? "color-rebecca-purple" : "color-eerie-black"}`}>{item.description}</p>
                                                                    </div>
                                                                </div>
                                                                <Icon icon="arrow-right"
                                                                    size={24}
                                                                    className="min-w-24px min-h-24px"
                                                                    color={activeTab.faq_type_id === item.faq_type_id && Color.rebecca_purple} />
                                                            </div>

                                                        </div>
                                                    )
                                                }

                                            </div>
                                            <div className="d-lg-none d-block mb-3">
                                                <CustomTabBar data={faqTypes}
                                                    selectedTab={activeTab.id}
                                                    onSelectTab={(id) => setActiveTab({
                                                        id: id,
                                                        faq_type_id: id
                                                    })} />
                                            </div>

                                        </div>
                                        <div className="col-xl-9 col-lg-8 e-faq  pt-md-0 pt-3">
                                            <div className="d-flex row-gap-8px justify-content-between align-items-center margin-18px-bottom">
                                                <div className="row w-100">
                                                    <div className="col-lg-8 col-11">
                                                        <div className="position-relative">
                                                            <Icon icon="Search"
                                                                size={20}
                                                                className="position-absolute e-search-icon" />

                                                            <input value={search}
                                                                placeholder={"Search for your issue"}
                                                                type='text'
                                                                className="color-black e-montserrat-medium e-font-14 e-line-height-20 w-100 no-outline padding-12px-all e-search-fund border-radius-12px padding-40px-left border-0"
                                                                onChange={_handleSearch}

                                                            // onKeyDown={_onPressEnter}
                                                            />
                                                            {/* {
                props.icon === true && props.inputState.length !== 0 &&
                <div className="position-absolute e-bg-rebecca-purple top-0 end-0 border-radius-tr-12px border-radius-br-12px h-100 d-flex align-items-center px-2" onClick={props.onSubmit}>
                    <Icon icon="Arrow-Right"
                        size={24}
                        color={Color.white} />
                </div>
            } */}

                                                            {/* {
                props.close === true &&
                <Icon icon="close-btn"
                    size={20}
                    color={Color.black}
                    className="position-absolute  top-0 right-8px bottom-0 my-auto cursor-pointer"
                    onClick={props.clearSearch} />
            } */}

                                                        </div>
                                                    </div>
                                                </div>
                                                <SecondaryButton label="My tickets"
                                                    image={require("../../Assets/Images/FAQ/ticket-star.png")}
                                                    width={24}
                                                    height={24}
                                                    className="padding-10px-tb px-3 e-bg-magnolia border-radius-40px"
                                                    onPress={() => _handleMyTickets(2)} />

                                            </div>
                                            <div className="border-1px border-bright-gray p-sm-4 p-3 border-radius-24px">
                                                <p className="e-montserrat-semi-bold e-font-16 e-line-height-32 color-american-purple margin-32px-bottom">{activeTab.name}</p>
                                                {
                                                    listLoader === true ?
                                                        <Loader height="h-80vh" />
                                                        :
                                                        faqList.length === 0 ?
                                                            <EmptyScreen className="my-5"
                                                                title="No faq found"
                                                                type={1}
                                                                description="" />
                                                            :
                                                            <div class={`accordion accordion-flush d-flex flex-column gap-8px e-faq-accordian`}
                                                                id="faqAccordian">
                                                                {
                                                                    faqList.map((item, key) =>
                                                                        <div key={key}
                                                                            className={`padding-32px-bottom e-accordian-item border-bottom-1px border-bright-gray ${key !== 0 && "pt-sm-4 pt-3"}`}>
                                                                            <h2 class={`d-flex justify-content-between align-items-center mb-2 py-0`}
                                                                                id={`heading-${key}`}>
                                                                                <button class={`e-montserrat-medium e-font-16 e-line-height-28 color-eerie-black accordion-button collapsed p-0 e-heading `}
                                                                                    type="button"
                                                                                    data-bs-toggle="collapse"
                                                                                    data-bs-target={`#collapse-${key}`}
                                                                                    aria-expanded="false"
                                                                                    aria-controls={`collapse-${key}`}>
                                                                                    {item.question}
                                                                                </button>

                                                                            </h2>
                                                                            <div id={`collapse-${key}`}
                                                                                class="accordion-collapse collapse"
                                                                                aria-labelledby={`heading-${key}`}
                                                                                data-bs-parent="#faqAccordian">

                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-24 color-outer-space mb-0">{item.answer}</p>

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            </div>
                                                }

                                                <p className="e-montserrat-semi-bold e-font-18 e-line-height-18 color-eminence mt-sm-4 mt-3">Have you tried searching for an answer?</p>
                                                <p className="e-montserrat-medium e-font-14 e-line-height-18 color-eerie-black mb-0">Create a ticket and we’ll reply as soon as we can, or come back later.</p>

                                                <PrimaryButton className="padding-12px-tb padding-20px-lr border-radius-12px mt-sm-4 mt-3"
                                                    label="Raise a ticket"
                                                    image={require('../../Assets/Images/FAQ/ticket.png')}
                                                    width={20}
                                                    height={20}
                                                    onPress={() => _handleMyTickets(1)} />


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    )
}

export default FAQ