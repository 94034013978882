
/*
 *   File : risk-confirm.js
 *   Author URI : https://evoqins.com
 *   Description : Confirm modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { PrimaryButton } from "../Buttons";
// import { ConsentCard } from "../Cards";
import { Icon } from "../Icon";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";
import Color from '../../Styles/color.module.scss'


// type  1 ---- success
// else failed

const RiskConfirmationModal = (props) => {
    // const [acceptedTerms, setAcceptedTerms] = useState(false);


    useEffect(() => {
        var my_modal = document.getElementById("risk-confirm");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="risk-confirm"
                tabIndex="-1"
                aria-labelledby="risk-confirm"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content text-center padding-20px-lr py-3 ${style.e_content} `}>
                        <Icon icon="close-btn"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            stroke={Color.steel_blue}
                            className='cursor-pointer position-absolute right-16px top-16px' />

                        <img src={require("../../Assets/Images/Modal/failed.png")}
                            alt="success"
                            draggable={false}
                            width={144}
                            height={144}
                            className="mx-auto" />
                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2">
                            Risk Profile Mismatch
                        </h5>

                        <p className="e-montserrat-medium e-font-14 e-line-height-24 color-charcoal-gray mb-0 ">
                            {props.description}
                        </p>
                        {/* <ConsentCard acceptedTerms={acceptedTerms} risk={true}
                            setAcceptedTerms={() => setAcceptedTerms(!acceptedTerms)} /> */}

                        <div className="row justify-content-center">
                            {/* {
                                props.hide_secondary ?
                                    null
                                    :
                                    <div className="col-6">
                                        <SecondaryButton label="Skip"
                                            cancel="modal"
                                            className="w-100 padding-12px-tb mt-4" />
                                    </div>
                            } */}
                            <div className="col-6">
                                <PrimaryButton label={"Continue"}
                                    cancel="modal"
                                    showLoader={false}
                                    className="w-100 padding-12px-tb mt-4"
                                    onPress={props.handleConfirm} />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

RiskConfirmationModal.defaultProps = {
    closeModal: () => { },
    handleConfirm: () => { },
}

export default memo(RiskConfirmationModal)