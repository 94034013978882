/*
 *   File : basket-comparison.js
 *   Author : https://evoqins.com
 *   Description : Container file to show the comparison chart and other info about Active and Passive baskets
 *   Integrations : NA
 *   Version : 1.0.0
*/

// Packages
import { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";

// Components
import { PassiveActiveComparison } from "../Others"
import { Breadcrumb } from "../../Components/Other";
import { Icon } from "../../Components/Icon";
import { Header } from "../../Components/Header";
import { VideoPreviewModal } from "../../Components/Modal";

const ActiveVsPassive = () => {

    const navigate = useNavigate();

    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        if (showPreview === true) {
            const modal = new Modal(document.getElementById("video-preview"));
            modal.show();
        }
    }, [showPreview]);


    function _handleNavigate() {
        navigate("/");
    }

    function _showPreview(status) {
        setShowPreview(status)
    }

    return (

        <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
            {/* Header */}
            <Header type={2} />

            <div className="row ">
                <div className="col-lg-11 pe-lg-0 padding-144px-bottom">
                    <div className="px-4">
                        <Breadcrumb mainPage="Home"
                            currentPage="Active vs Passive"
                            handleNavigate={_handleNavigate}
                            type={1} />
                        <Icon icon="Arrow-Right"
                            size={32}
                            onClick={_handleNavigate}
                            className="margin-32px-top e-transform-left-arrow cursor-pointer" />
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-10 col-md-8">
                            <PassiveActiveComparison goal_id={null} />
                            <div className="px-4">
                                <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black my-4">More to know</p>
                                <div className="row">
                                    <div className="col-xl-8 col-lg-12 col-md-6 col-sm-6">
                                        <div className="e-banner">
                                            <div className="position-relative" onClick={() => _showPreview(true)}>
                                                <img src={require("../../Assets/Images/Basket/more-to-know.png")}
                                                    alt="More to know"
                                                    draggable={false}
                                                    className="img-fluid e-banner-img" />
                                                <img src={require("../../Assets/Images/financial-freedom/play.gif")}
                                                    alt="play"
                                                    width={84}
                                                    className="position-absolute start-0 end-0 top-0 bottom-0 m-auto cursor-pointer"
                                                />
                                            </div>
                                        </div>
                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-28 color-eerie-black margin-12px-bottom mt-3">
                                            Active vs Passive: Choosing the Right Mix
                                        </p>
                                        <p className="e-montserrat-regular e-font-14 e-line-height-28 color-jet mb-0">
                                            Investing your money should not be confusing. Whether you’re new to investing or looking to diversify your portfolio, understanding the difference between active and passive funds is crucial. This short video will help you navigate these investment strategies, ensuring you can make an informed decision.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showPreview === true &&
                <VideoPreviewModal url="https://mintit-prod-static-assets.s3.ap-south-1.amazonaws.com/videos/ACTIVE_V_S_PASSIVE.mp4"
                    size={300}
                    closeModal={() => {
                        _showPreview(false);
                    }} />
            }
        </div>

    )
}

export default ActiveVsPassive