/*
*   File : passive-active-comparison.js
*   Author : https://evoqins.com
*   Description : -
*   Version : 1.0.0
*/

import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { LineChart } from "../../Components/Charts";
import { CustomTabBar } from "../../Components/Tab";
import { Loader } from "../../Components/Other";
import { DataTableContainer } from "../../Components/Table";
import { Icon } from "../../Components/Icon";

import Color from "../../Styles/color.module.scss"
import style from "../../Styles/Components/area.module.scss";

import APIService from "../../Services/api-service";

// const COMPARE_OPTIONS = [{
//     filter_type: 1,
//     period: "3M"
// },
// {
//     filter_type: 2,
//     period: "6M"
// },
// {
//     filter_type: 3,
//     period: "1Y"
// },
// {
//     filter_type: 4,
//     period: "3Y"
// },
// {
//     filter_type: 5,
//     period: "5Y"
// },
// {
//     filter_type: null,
//     period: "All"
// }]


// Table style
const COMPARISON_TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },
    headRow: {
        style: {
            backgroundColor: 'transparent',
            borderBottomColor: 'transparent',
            paddingLeft: "0px",
            // color: Color.primary,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "12px",
            // paddingBottom: '12px',
        }
    },
    headCells: {
        style: {
            padding: "0px"
        }
    },
    rows: {
        style: {
            padding: '12px 0px 12px 0',
            '&:not(:last-child)': {
                borderBottom: `1px solid ${Color.onyx}`
            },
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px !important',
            paddingRight: "0px"
        }
    }

};

// TAB data
const TAB_DATA = [
    {
        id: 1,
        label: "Active",
        icon: require("../../Assets/Images/Goal/active-tab.svg").default
    },
    {
        id: 2,
        label: "Passive",
        icon: require("../../Assets/Images/Goal/passive-tab.svg").default
    }
];

const PassiveActiveComparison = (props) => {
    const navigate = useNavigate();

    const [selectedCompare, setSelectedCompare] = useState(null);
    const [tabIndex, setTabIndex] = useState(TAB_DATA[0].id);
    const [compareData, setCompareData] = useState({});
    const [compareLoader, setCompareLoader] = useState(true);
    const [passiveReturns, setPassiveReturns] = useState([]);
    const [chartLabels, setChartLabels] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [passiveData, setPassiveData] = useState([]);
    const [compareFilter, setCompareFilter] = useState([])

    useEffect(() => {
        _compareData();
        // eslint-disable-next-line
    }, [selectedCompare]);

    // Table columns
    const COMPARISON_COLUMNS = [
        {
            name: "Fund Name",
            cell: row => <span className="color-eerie-black e-font-14 e-line-height-18 e-montserrat-medium">{row.name}</span>,
            sortable: false,
            width: "70%"
        },
        {
            name: "Trailing Returns",
            cell: row => <span className={`${row.returns > 0 ? "color-go-green" : row.returns < 0 ? "color-deep-carmine-pink" : "color-eerie-black"} e-font-14 e-line-height-24 e-montserrat-semi-bold`}>{row.returns ? `${row.returns}%` : "-"}</span>,
            sortable: false,
            right: true,
        },
    ]

    const _handleCompare = (value) => {
        setSelectedCompare(value);
        setCompareLoader(true);
    }

    // handle Tab
    function _handleTab(tab_index) {
        setTabIndex(tab_index);
    }

    // API - compare data
    const _compareData = (is_initial = false) => {
        const url = "/goal/compare-baskets";
        const request = {
            goal_id: props.goal_id,
            filter_id: selectedCompare,
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setCompareData(response.data);
                const chart_labels = [];
                const active_returns = [];
                const passive_returns = [];
                const chart_filter = [];

                response.data.active_date.map((item) => {
                    return (
                        chart_labels.push(item)
                    )
                })

                response.data.filter_data.map((item) => {
                    return (
                        chart_filter.push({
                            filter_type: item.id,
                            period: item.label
                        })
                    )
                })

                setCompareFilter(chart_filter);


                response.data.active_return.map((item) => {
                    return (
                        active_returns.push(item)
                    )
                })

                response.data.passive_return.map((item) => {
                    return (
                        passive_returns.push(item)
                    )
                })

                setChartLabels(chart_labels);
                setActiveData(active_returns);
                setPassiveData(passive_returns);

                setPassiveReturns(response.data.passive_return)
            } else {
                toast.error(response.message, {
                    type: "error",
                });
                navigate(-1);
            }
            setCompareLoader(false);
        });
    }



    return (
        <div className="px-4">
            <div className="mt-4">
                <h5 className="color-eerie-black e-line-height-28 e-font-14 e-montserrat-semi-bold mb-1">Comparative Analysis</h5>
                <p className="color-blue-gray mb-0 e-font-14 e-line-height-24 e-montserrat-regular">Below graph shows returns comparison between Active & Passive funds basket in selected time frames.</p>
            </div>


            <div className="padding-12px-bottom mt-2">
                <div className="border-1px border-light-periwinkle border-radius-16px p-3 bg-white">
                    {
                        compareLoader === true ?
                            <Loader height="e-goal-chart-height" />
                            :
                            chartLabels.length > 0 &&
                            <div className="e-goal-chart-height w-100">
                                <LineChart labels={chartLabels}
                                    dataset1={activeData}
                                    dataset2={passiveData}
                                    borderColor1={Color.sky_blue}
                                    borderColor2={Color.sunglow} />
                            </div>
                    }

                    <div className="d-flex justify-content-center w-100 mt-3">
                        <div className="d-flex flex-wrap gap-16px p-1 e-bg-cultured border-radius-16px">
                            {
                                compareFilter.map((item, key) =>
                                    <span key={key}
                                        className={`e-font-16 e-line-height-22 e-fund-filter w-fit-content cursor-pointer padding-6px-tb padding-12px-lr border-1px
                                                                                            ${selectedCompare === item.filter_type ? `e-montserrat-semi-bold color-rebecca-purple  border-platinum bg-white border-radius-8px ${style.e_year}` : "color-chinese-black e-montserrat-regular border-transparent"}`}
                                        onClick={() => {
                                            if (selectedCompare !== item.filter_type) {
                                                _handleCompare(item.filter_type)
                                            }
                                        }}>
                                        {item.period}
                                    </span>
                                )
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center gap-14px mt-4">
                        <div className="d-flex align-items-center gap-8px">
                            <span style={{ width: "8px", height: "8px", backgroundColor: Color.sky_blue, borderRadius: "100%" }}></span>
                            <span className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black">
                                Active Basket {parseFloat(compareData.active_return_percentage) > 0
                                    ? <span className="color-green">({compareData.active_return_percentage})</span>
                                    : <span className="color-crimson-red">({compareData.active_return_percentage})</span>}
                            </span>

                        </div>
                        {
                            passiveReturns.length !== 0 &&
                            <div className="d-flex align-items-center gap-8px">
                                <span style={{ width: "8px", height: "8px", backgroundColor: Color.sunglow, borderRadius: "100%" }}></span>
                                <span className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black">
                                    Passive Basket {parseFloat(compareData.passive_return_percentage) > 0
                                        ? <span className="color-green">({compareData.passive_return_percentage})</span>
                                        : <span className="color-crimson-red">({compareData.passive_return_percentage})</span>}
                                </span>

                            </div>
                        }
                    </div>

                    <div className="d-flex align-items-start padding-12px-all mt-4 e-bg-ivory-bridge border-radius-12px  color-vivid-orange e-font-12 e-line-height-20 gap-8px e-montserrat-medium">
                        <span>
                            <Icon icon="info-circle-yellow"
                                size={16} />
                        </span>
                        Comparison is shown only for the time frame based on longevity of all the funds from Active and Passive baskets.
                    </div>

                </div>

                <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-3 mt-4">Other Comparisons</p>

                <div className="row mb-sm-3">
                    <div className="col-sm-7 col-5"></div>
                    <div className="col">
                        <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black ">Active</p>
                    </div>

                    <div className="col">
                        <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black ">Passive</p>
                    </div>
                </div>
                <div className="row mb-sm-4 mb-2">
                    <div className="col-sm-7 col-5">
                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black mb-0">Avg. Expense ratio</p>
                    </div>
                    <div className="col">
                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">{compareData.active_expense_ratio}</p>
                    </div>

                    <div className="col">
                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">{passiveReturns.length !== 0 ? compareData.passive_expense_ratio : "-"}</p>
                    </div>

                </div>
                <div className="row mb-sm-4 mb-2">
                    <div className="col-sm-7 col-5">
                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black mb-0">Avg. AUM</p>
                    </div>
                    <div className="col">
                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">{compareData.active_aum}</p>
                    </div>

                    <div className="col">
                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">{passiveReturns.length !== 0 ? compareData.passive_aum : "-"}</p>
                    </div>

                </div>
                <div className="row mb-sm-4 mb-2">
                    <div className="col-sm-7 col-5">
                        <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black mb-0">Avg. Funds Age</p>
                    </div>
                    <div className="col">
                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">{compareData.active_funds_duration}</p>
                    </div>

                    <div className="col">
                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-20 color-eerie-black mb-0">{passiveReturns.length !== 0 ? compareData.passive_funds_duration : "-"}</p>
                    </div>

                </div>
                <div className="border-bottom-1px border-chinese-white ">
                    <CustomTabBar data={TAB_DATA}
                        selectedTab={tabIndex}
                        type={2}
                        onSelectTab={_handleTab} />
                </div>
                {/* <h5 className="e-font-14 e-line-height-24 color-eerie-black e-montserrat-semi-bold margin-12px-tb">Fund Breakdown</h5> */}

                {
                    tabIndex === 1 ?
                        <DataTableContainer
                            columns={COMPARISON_COLUMNS}
                            data={compareData.active_comparison_data}
                            customStyles={COMPARISON_TABLE_STYLE} />
                        :
                        passiveReturns.length > 0 ?
                            <DataTableContainer
                                columns={COMPARISON_COLUMNS}
                                data={tabIndex === 1 ? compareData.active_comparison_data : compareData.passive_comparison_data}
                                customStyles={COMPARISON_TABLE_STYLE} />
                            :
                            <p className="e-montserrat-medium e-font-14 e-line-height-20 mb-0 color-outer-space mt-3">
                                Since there are no passive funds available in suggested combination, kindly proceed with active funds. Passive funds shall be made available when we get such desired allocation
                            </p>
                }

            </div>

        </div>
    )
}

export default PassiveActiveComparison