
/*
 *   File : fund-redemption.js
 *   Author URI : https://evoqins.com
 *   Description : Modal component to submit redemption request.
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Components
import { Icon } from "../Icon";
import { LinkButton, PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomAmountInput, CustomPINInput, CustomTextInput, RadioGroup } from "../FormElements";

// Services, helper
import APIService from "../../Services/api-service";
import { useCountdown } from "../../Helper/helper";
import { _generate2Fa } from "../../Helper/api";

// Styles
import style from "../../Styles/Components/fund-redemption.module.scss"
import Color from "../../Styles/color.module.scss"


const REDEMPTION_TYPES = [

    {
        name: "Amount",
        id: 2
    },
    {
        name: "Unit",
        id: 1
    },
];

const FundRedemptionModal = (props) => {

    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;
    const { seconds, resetCountdown } = useCountdown(30);
    const navigate = useNavigate();

    const [screenNumber, setScreenNumber] = useState(1);
    const [unit, setUnit] = useState(props.unit);
    const [unitError, setUnitError] = useState("");
    const [amount, setAmount] = useState(props.amount);
    const [amountError, setAmountError] = useState("");
    const [redeemAll, setRedeemAll] = useState(false);
    const [screen1Loader, setScreen1Loader] = useState(false);

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [screen2Loader, setScreen2Loader] = useState(false);
    const [successData, setSuccessData] = useState({});

    const [redemptionType, setRedemptionType] = useState(props.redemptionType);

    useEffect(() => {
        var my_modal = document.getElementById("fund-redeem");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (redeemAll) {
            setAmountError("");
            setAmount(props.data.redeemable_amount);
            setUnit(props.data.units);
            setUnitError("");
        }
        // eslint-disable-next-line
    }, [redeemAll])

    const _handleUnitChange = (value) => {
        setUnit(value);
        setUnitError("");
    };

    const _handleRedeemAll = () => {
        setRedeemAll(!redeemAll);
        if (redeemAll === true) {
            setAmount("");
            setUnit("");
        }
    }

    const _handleRedeem = () => {
        let valid = true;
        if (redemptionType === 1) {
            if (unit === "") {
                setUnitError("Please enter the unit");
                valid = false;
            }
        }
        else {
            if (amount === "") {
                setAmountError("Please enter the amount");
                valid = false
            }
        }
        if (valid === true) {
            setScreen1Loader(true);
            _redeem();
        }
    }

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // handle submit otp validation
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            setScreen2Loader(true);
            _confirmRedeem();
        }
    }

    const _navigateToOrderDetail = () => {
        if (successData.status === 1) {
            navigate("/orders/fund-order", {
                state: {
                    id: successData.order_id,
                    paymentTypes: [],
                    orderStatus: [],
                    orderTypes: [],
                    startDate: "DD-MM-YYYY",
                    endDate: "DD-MM-YYYY",
                    type: 2,
                    page: 1 // TODO
                }
            })
        }
        else {
            navigate("/")
        }
    }

    // handle redemption type
    function _handleRedemptionType(id) {
        setRedemptionType(id);

    }

    function _handleAmount(value) {


        // Regular expression to allow numbers and up to three decimal places
        const regex = /^\d*\.?\d{0,3}$/;

        // Check if the new unit matches the regex
        if (regex.test(value)) {
            if (value <= props.data.redeemable_amount) {
                setAmount(value);
                setAmountError("");
            }
        }
    }


    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("MF")
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };


    // API - redeem
    const _redeem = () => {
        const url = "/mf/confirm-redeem";

        const request = {
            "mf_id": props.data.mf_id,
            "redeem_all": redeemAll,
            "units": redeemAll === true ? null : redemptionType === 1 ? unit : null,
            "amount": redeemAll === true ? null : redemptionType === 2 ? amount : null
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setScreenNumber(2);
                resetCountdown();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setScreen1Loader(false);
        });
    }

    // API - confirm redeem
    const _confirmRedeem = () => {
        const url = "/mf/redeem";

        const request = {
            "mf_id": props.data.mf_id,
            "redeem_all": redeemAll,
            "units": redeemAll === true ? null : redemptionType === 1 ? unit : null,
            "amount": redeemAll === true ? null : redemptionType === 2 ? amount : null,
            "otp": otp
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setScreenNumber(3);

                setSuccessData({
                    order_id: response.data.order_id,
                    status: 1,
                    image: require("../../Assets/Images/Modal/success.png"),
                    title: "Redeem order created",
                    description: response.data.t_days
                });

            } else {
                setSuccessData({
                    image: require("../../Assets/Images/Modal/failed.png"),
                    status: 0,
                    title: "Redeem request failed",
                    description: ""
                });
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setScreen2Loader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_fund_redeem}`}
            id="fund-redeem"
            tabIndex="-1"
            aria-labelledby="fund-redeem"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false" >
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content pb-4 ${style.e_content} `}>
                    {
                        (screenNumber === 1 || screenNumber === 2) &&
                        <div className="border-bottom-1px border-ash-gray px-4">
                            <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                {
                                    screenNumber === 1 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        Redemption
                                    </h5>
                                }
                                {
                                    screenNumber === 2 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        <Icon icon="Arrow-Right"
                                            size={22}
                                            className="e-transform-left-arrow cursor-pointer me-2"
                                            onClick={() => setScreenNumber(1)} /> Enter OTP
                                    </h5>
                                }
                                <Icon icon="close-btn"
                                    size={24}
                                    color={Color.eerie_black}
                                    id="close-fund"
                                    className="cursor-pointer"
                                    data-bs-dismiss="modal" />
                            </div>
                        </div>
                    }
                    {
                        screenNumber === 1 ?
                            <div className="px-4">

                                <div className="my-3 p-3 border-1px border-ash-gray border-radius-24px">
                                    <div className="d-flex align-items-center gap-8px">
                                        <img src={props.data.icon}
                                            alt={props.data.name}
                                            width={38}
                                            height={38}
                                            draggable={false}
                                            className="border-radius-12px object-fit-contain border-1px border-silver-sand " />
                                        <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{props.data.name}</p>
                                    </div>
                                    <div className="row row-gap-8px mt-sm-3 mt-2">
                                        <div className="col-sm-6">
                                            <span className="color-outer-space e-font-14 e-montserrat-regular e-line-height-16 mb-2">
                                                Units
                                            </span>
                                            <p className="color-eerie-black e-font-14 e-montserrat-semi-bold e-line-height-20 mb-0">{props.data.units}</p>
                                        </div>
                                        <div className="col-sm-6">
                                            <span className="color-outer-space e-font-14 e-montserrat-regular e-line-height-16 mb-2">
                                                Approx. value
                                            </span>
                                            <p className="color-eerie-black e-font-14 e-montserrat-semi-bold e-line-height-20 mb-0">{props.data.redeemable_amount_text}</p>
                                        </div>
                                        <div className="col-sm-6 mt-sm-2 mt-0">
                                            <p className="color-outer-space e-font-14 e-montserrat-regular e-line-height-16 mb-0">
                                                Redemption time
                                            </p>
                                            <LinkButton label="View TAT"
                                                className="e-font-14"
                                                onPress={() => window.open(`${window.location.origin}/view-tat`, "_blank")} />
                                        </div>
                                    </div>
                                </div>
                                <h5 className='e-font-14 mb-1 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                    Redeem type
                                </h5>
                                <RadioGroup data={REDEMPTION_TYPES}
                                    selected={redemptionType}
                                    handleSelect={_handleRedemptionType} />
                                {
                                    redemptionType === 1 ?
                                        <div className="position-relative mt-3">
                                            <CustomTextInput label="Unit"
                                                postfix="*"
                                                value={unit}
                                                error={unitError}
                                                isDisabled={redeemAll}
                                                type="number"
                                                placeholder="1.2"
                                                handleChange={_handleUnitChange} />
                                            {/* <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0 position-absolute top-40px right-20px">{props.data.redeemable_amount_text}</p> */}
                                        </div>

                                        :
                                        <div className="position-relative mt-3">
                                            <CustomAmountInput
                                                value={amount}
                                                error={amountError}
                                                postfix="*"
                                                disabled={redeemAll}
                                                label="Amount"
                                                placeholder="50,000"
                                                handleChange={_handleAmount} />
                                            {/* <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0 position-absolute top-40px right-20px">{props.data.redeemable_amount_text}</p> */}
                                        </div>
                                }
                                <div className="d-flex align-items-center gap-4px mt-4 pt-2">
                                    <Icon icon={redeemAll === true ? "checked" : "unchecked"}
                                        size={24}
                                        className="cursor-pointer"
                                        onClick={_handleRedeemAll} />
                                    <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-0 cursor-pointer"
                                        onClick={_handleRedeemAll}>Redeem all</p>
                                </div>
                                <div className="row justify-content-center margin-32px-top">
                                    <div className="col-6">
                                        <PrimaryButton className="padding-10px-tb border-radius-12px w-100"
                                            label="Redeem"
                                            disabled={screen1Loader}
                                            showLoader={screen1Loader}
                                            onPress={_handleRedeem} />
                                    </div>
                                </div>
                            </div>
                            :
                            screenNumber === 2 ?
                                <div className="px-4 padding-14px-top">
                                    <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                        An OTP has been sent to your PAN linked mobile
                                        number.
                                    </p>
                                    <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">+91-  {MASKED_NUMBER}</h6>
                                    <CustomPINInput id="otp"
                                        autoFocus={true}
                                        pinString={otp}
                                        error={otpError}
                                        postfix="*"
                                        label="Enter OTP"
                                        onSubmit={_submitOTP}
                                        handleChange={_handleOTP} />
                                    <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                            ${seconds === 0 ?
                                            'cursor-pointer'
                                            : 'disabled'}`}
                                        onClick={_handleResend}>
                                        {seconds === 0 ?
                                            'Resend OTP'
                                            : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                    </span>
                                    </p>

                                    <div className="row">
                                        <div className="col-6">
                                            <SecondaryButton label="Cancel"
                                                cancel="modal"
                                                className="padding-12px-tb w-100 e-bg-magnolia" />
                                        </div>
                                        <div className="col-6">
                                            <PrimaryButton label="Confirm"
                                                className="padding-12px-tb  w-100"
                                                disabled={screen2Loader}
                                                onPress={_submitOTP} />
                                        </div>
                                    </div>

                                </div>
                                :

                                <div className="text-center px-4">
                                    <img src={successData.image}
                                        alt={successData.title}
                                        draggable={false}
                                        width={144}
                                        height={144}
                                        className="mx-auto" />
                                    <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">
                                        {successData.title}
                                    </h5>

                                    <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mt-2 mb-0 ">
                                        {successData.description} {successData.status === 1 && <LinkButton label="View TAT"
                                            className="e-font-14"
                                            onPress={() => window.open(`${window.location.origin}/view-tat`, "_blank")} />}
                                    </p>

                                    <div className="row d-flex justify-content-center mt-4">
                                        <div className="row">
                                            {successData.status === 1 && <div className="col-lg-6">
                                                <SecondaryButton label="View Order"
                                                    className="w-100 padding-12px-tb mt-4"
                                                    cancel="modal"
                                                    onPress={_navigateToOrderDetail} />
                                            </div>}

                                            <div className="col-6">
                                                <PrimaryButton label={successData.status === 1 ? "Portfolio" : "View Portfolio"}
                                                    className="w-100 padding-12px-tb mt-4"
                                                    cancel="modal"
                                                    onPress={props.redeemSuccess} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                    }
                </div>
            </div>
        </div>
    )
}

FundRedemptionModal.defaultProps = {
    amount: "",
    unit: "",
    redemptionType: REDEMPTION_TYPES[0].id
}

export default FundRedemptionModal