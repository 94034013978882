/*
*   File : list-banks.js
*   Author : https://evoqins.com
*   Description : Listing page to show all banks added in the application.
*   Version : 1.0.0
*/


import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Header } from "../../Components/Header";
import { Breadcrumb, Loader } from "../../Components/Other";
import { Icon } from "../../Components/Icon";
import { Modal } from "bootstrap";
import { ManageBank } from "../../Components/Modal";

import APIService from "../../Services/api-service";

const ListBanks = () => {

    const navigate = useNavigate();
    const [apiLoader, setApiLoader] = useState(true);
    const [activeBank, setActiveBank] = useState({});
    const [bankList, setBankList] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(null);

    useEffect(() => {
        _getBankList();
    }, []);

    useEffect(() => {
        if (openEditModal !== null) {
            const modal = new Modal(document.getElementById('manage-bank'));
            modal.show();
        }
    }, [openEditModal]);


    function _handleNavigate() {
        navigate("/");
    }

    const _handleActiveBank = (item) => {
        setActiveBank(item);
    }

    const _addBank = (type) => {
        setOpenEditModal(type)
    }

    // API - 
    const _getBankList = () => {
        const url = "/profile/bank/list";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setBankList(response.data);
                setActiveBank(response.data[0]);
            } else {
                setBankList([]);
                setActiveBank({});
            }
            setApiLoader(false);
        });
    }

    const InfoDetail = (props) => {
        return (
            <Fragment>
                <p className="e-montserrat-medium e-font-14 e-line-height-20 color-outer-space mb-1">{props.heading}</p>
                <div className="d-flex align-items-center gap-4px">
                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0">{props.detail}</p>
                    {
                        props.image &&
                        <img src={require("../../Assets/Images/manage-bank/verified-bank.png")}
                            alt="Manage bank"
                            width={20}
                            height={20} />
                    }
                </div>
            </Fragment>
        )
    }

    return (
        apiLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    {/* Header */}
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 pe-lg-0 padding-144px-bottom">

                            <div className="px-4">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Manage Bank Account"
                                    handleNavigate={_handleNavigate}
                                    type={1} />
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <p className="e-montserrat-semi-bold e-font-18 e-line-height-38 color-dark-jungle-green margin-12px-bottom margin-30px-top">Manage Bank Account</p>

                                    <div className="d-lg-none d-block">
                                        <div className="d-flex align-items-center gap-4px cursor-pointer e-bg-light-lavender border-radius-44px w-max-content padding-10px-tb ps-2 pe-3"
                                            onClick={() => _addBank(1)}>
                                            <Icon icon="add"
                                                size={24} />
                                            <span className="e-montserrat-medium e-font-14 e-line-height-18 color-rebecca-purple">Add Another Bank</span>
                                        </div>

                                    </div>
                                </div>
                                {
                                    bankList.length === 0 ?
                                        <Fragment>
                                            <div className="row d-flex justify-content-center margin-70px-top text-center">
                                                <div className="col-lg-4 col-md-6 col-sm-8">
                                                    <img src={require("../../Assets/Images/Profile/Manange-nominee.png")} alt="Manage nominee"
                                                        width={282}
                                                        height={210}
                                                    />
                                                    <p className="e-montserrat-medium e-font-16 e-line-height-26 color-outer-space margin-32px-bottom margin-12px-top">We recommend adding a bank details
                                                        to ensure seamless continuation of your investments</p>
                                                </div>
                                            </div>
                                        </Fragment>
                                        :
                                        <div className="row row-gap-16px">
                                            <div className="col-lg-4">
                                                <div className="e-profile-border border-radius-24px d-lg-block d-none">
                                                    {
                                                        bankList.map((item, key) =>
                                                            <div className={`${activeBank.id === item.id && "e-bg-light-lavender"} 
                                                        ${key === 0 && "border-radius-tlr-24px"} 
                                                        ${key !== bankList.length - 1 && "border-bottom-1px border-chinese-white"}
                                                        px-4 pt-4 padding-22px-bottom cursor-pointer`}
                                                                key={key}
                                                                onClick={() => _handleActiveBank(item)}>
                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 color-outer-space mb-2">Bank name</p>
                                                                <div className="d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center gap-8px">
                                                                        <img src={item.image}
                                                                            alt={item.name}
                                                                            width={40}
                                                                            height={40} />
                                                                        <div>
                                                                            <p className="e-montserrat-medium e-font-16 e-line-height-22 color-eerie-black mb-0">{item.name}</p>
                                                                            <p className="e-montserrat-medium e-font-16 e-line-height-22 color-dim-grey mb-0">{item.masked_account_number}</p>
                                                                        </div>
                                                                    </div>

                                                                    <Icon icon="arrow-right"
                                                                        size={24} />
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                    <div className="px-4 py-3 border-top-1px border-chinese-white">
                                                        <div className="d-flex align-items-center gap-4px cursor-pointer e-bg-light-lavender border-radius-44px w-max-content padding-10px-tb ps-2 pe-3"
                                                            onClick={() => _addBank(1)}>
                                                            <Icon icon="add"
                                                                size={24} />
                                                            <span className="e-montserrat-medium e-font-14 e-line-height-18 color-rebecca-purple">Add Another Bank</span>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="d-lg-none d-block  ">
                                                    <div className="overflow-x-auto white-space-nowrap d-flex gap-8px">
                                                        {
                                                            bankList.map((item, key) =>
                                                                <div className={`${activeBank.id === item.id ? "e-bg-light-lavender border color-rebecca-purple" : "color-eerie-black border"} border-radius-24px px-3 py-2 cursor-pointer color-eerie-black e-montserrat-medium e-font-12 e-line-height-14`}
                                                                    key={key}
                                                                    onClick={() => _handleActiveBank(item)}>
                                                                    {item.name}
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="e-profile-border border-radius-24px">
                                                    <div className="p-sm-4 p-3 d-flex align-items-center justify-content-between border-bottom-1px border-chinese-white">
                                                        <div className="d-flex align-items-sm-center align-items-start gap-12px">
                                                            <img src={activeBank.image}
                                                                alt={activeBank.name}
                                                                width={48}
                                                                height={48}
                                                                draggable={false}
                                                                className="d-sm-block d-none" />
                                                            <img src={activeBank.image}
                                                                alt={activeBank.name}
                                                                width={38}
                                                                height={38}
                                                                draggable={false}
                                                                className="d-sm-none d-block" />
                                                            <div>
                                                                <p className="e-montserrat-semi-bold e-font-18 e-line-height-20 color-eerie-black mb-0">{activeBank.name}</p>
                                                                {
                                                                    activeBank.primary === true &&
                                                                    <p className="e-montserrat-medium e-font-16 e-line-height-22 color-rebecca-purple mt-2 mb-0">(Primary bank)</p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <span>
                                                            <Icon icon="edit-border"
                                                                size={40}
                                                                className="cursor-pointer"
                                                                onClick={() => _addBank(2)} />
                                                        </span>
                                                    </div>
                                                    <div className="px-sm-4 px-3 pb-sm-4 pb-3 pt-3">
                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black">Bank Details details</p>
                                                        <div className="row row-gap-8px mb-4">
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4 col-6">
                                                                <InfoDetail heading="Account holder"
                                                                    detail={activeBank.account_holder} />
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-6   col-sm-4 col-6">
                                                                <InfoDetail heading="IFSC code"
                                                                    detail={activeBank.ifsc_code} />
                                                            </div>

                                                        </div>
                                                        <div className="row row-gap-8px">
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4 col-6">
                                                                <InfoDetail heading="Account type "
                                                                    detail={activeBank.account_type} />
                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4 col-6">
                                                                <InfoDetail heading="Account number"
                                                                    image={true}
                                                                    detail={activeBank.masked_account_number} />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    openEditModal !== null &&
                    <ManageBank data={openEditModal === 1 ? {} : activeBank}
                        closeModal={() => setOpenEditModal(null)}
                        submitInvestment={() => _getBankList()}
                        reSubmit={() => setOpenEditModal(null)} />
                }
            </Fragment>
    )
}


export default ListBanks