
/*
 *   File : cancel-sip.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to cancel sip
 *   Integrations : null
 *   Version : v1.1
 */


// Packages
import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

// Components
import { CustomSelectBox } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Styles
import style from "../../Styles/Components/cancel-sip.module.scss";

// Services
import APIService from "../../Services/api-service";

const CancelSIPModal = (props) => {


    const [selectedReason, setSelectedReason] = useState(null);
    const [selectedReasonError, setSelectedReasonError] = useState("");
    const [cancelLoader, setCancelLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("cancel-sip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    const _handleSelectBox = (value) => {
        setSelectedReason(value);
        setSelectedReasonError("");
    }

    // API - Cancel sip
    const _cancelSIP = () => {
        if (selectedReason === null) {
            setSelectedReasonError("Please select reason for cancellation");
        } else {
            setCancelLoader(true);

            const url = "/sip/cancel";

            const request = {
                "sip_id": props.id,
                "cancellation_reason_id": selectedReason.value
            }

            APIService(true, url, request).then((response) => {
                if (response.status_code === 200) {
                    document.getElementById("close-modal").dispatchEvent(new Event("click"));
                    toast.success("SIP Cancelled", {
                        type: 'success'
                    });
                    props.success();
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: 'error'
                    });
                }
                setCancelLoader(false);
            });
        }
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_cancel_sip}`}
                id="cancel-sip"
                tabIndex="-1"
                aria-labelledby="cancel-sip"
                aria-hidden="true"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4 px-4 ${style.e_content} `}>
                        <span id="close-modal"
                            className="d-none"
                            data-bs-dismiss="modal" ></span>

                        <Fragment>
                            <img src={require("../../Assets/Images/Modal/delete.png")}
                                alt="success"
                                draggable={false}
                                width={144}
                                height={144}
                                className={`mx-auto ${style.e_cancel}`} />
                            <h5 className="e-font-18 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2 text-center">
                                Do you want to Cancel this SIP?
                            </h5>
                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-quartz text-center mb-4">
                                Please confirm the cancellation of the SIP by selecting the reason and confirming.
                            </p>


                            <CustomSelectBox placeholder={selectedReason === null ? "Select cancellation reason" : ""}
                                label="Why do you want to cancel this SIP?"
                                postFix={true}
                                className="w-100"
                                value={selectedReason}
                                error={selectedReasonError}
                                options={props.options}
                                onSelectChange={_handleSelectBox} />
                            <div className="row margin-32px-top">
                                <div className="col-6">
                                    <SecondaryButton label="No, Cancel"
                                        cancel="modal"
                                        className="padding-10px-tb w-100 e-bg-lavender-frost" />
                                </div>
                                <div className="col-6">
                                    <PrimaryButton label="Yes, I want"
                                        className="padding-10px-tb  w-100"
                                        disabled={cancelLoader}
                                        onPress={_cancelSIP} />
                                </div>
                            </div>


                        </Fragment>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CancelSIPModal