/*
*   File : sip.js
*   Author : https://evoqins.com
*   Description : A common listing page for all SIPs in the platform. 
*   Version : 1.0.0
*/
// import packages
import { Fragment, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// import components
import { CustomTabBar } from "../../Components/Tab";
import { CustomSelectBox } from "../../Components/FormElements";
import { EmptyScreen, Loader } from "../../Components/Other";
import { Icon } from "../../Components/Icon";
import { PrimaryButton, SecondaryButton } from "../../Components/Buttons";
import { CancelSIPModal, EditSIP, PauseSIPModal, StepUpSIP } from "../../Components/Modal";

// import services
import Color from "../../Styles/color.module.scss";

// import services,helper
import APIService from "../../Services/api-service";
import { useWindowSize } from "../../Helper/helper";
import Popup from "reactjs-popup";
import { useLocation } from "react-router-dom";

const SIP_TABS = [
    {
        label: "Active",
        id: 1,
        icon: require("../../Assets/Images/Systematic-transaction/active-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/active.svg").default,
    },

    {
        label: "Executed",
        id: 2,
        icon: require("../../Assets/Images/Systematic-transaction/executed-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/executed-active.svg").default,

    },
    {
        label: "Cancelled",
        id: 3,
        icon: require("../../Assets/Images/Systematic-transaction/cancelled-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/cancelled-active.svg").default,

    },
    {
        label: "Paused",
        id: 4,
        icon: require("../../Assets/Images/Systematic-transaction/paused-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/paused-active.svg").default,

    },
    {
        label: "Completed",
        id: 5,
        icon: require("../../Assets/Images/Systematic-transaction/completed-inactive.svg").default,
        active: require("../../Assets/Images/Systematic-transaction/completed-inactive.svg").default,

    }
]

const SIP_OPTIONS = [{
    label: "Milestones",
    value: 1,
},
{
    label: "Individual funds",
    value: 2,
},
{
    label: "Baskets",
    value: 3,
}]

// const TABLE_STYLE = {
//     table: {
//         style: {
//             borderRadius: "0px",
//             backgroundColor: 'transparent'
//         },
//     },
//     head: {
//         style: {
//             height: "0px"
//         }
//     },
//     headRow: {
//         style: {
//             borderBottomColor: 'transparent',

//         }
//     },
//     rows: {
//         style: {
//             cursor: "pointer",
//             // '&:not(:last-child)': {
//             // },
//             borderBottom: `1px solid ${Color.gainsboro}`,
//             '&:hover': {
//                 // boxShadow: "5px 3px 15px 0px #0000001A, 0px -3px 15px 0px #0000001A",
//                 backgroundColor: Color.Magnolia
//             },
//             padding: "24px",
//             backgroundColor: 'transparent',
//         },
//     },
//     cells: {
//         style: {
//             paddingLeft: '0px !important'
//         }
//     }

// }

const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}

const SIP = () => {
    const location = useLocation();
    const { width } = useWindowSize();
    const [sipTabs, setSIPTabs] = useState(SIP_TABS[0].id);
    const [selectedSip, setSelectedSip] = useState(SIP_OPTIONS[0]);
    const [pageCount, setPageCount] = useState(0); //total number of pages
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [activeSipList, setActiveSipList] = useState([]);
    const [activeSipLoader, setActiveSipLoader] = useState(true);
    const [activeExpandedRow, setActiveExpandedRow] = useState({});
    const [showCancelSIPModal, setShowCancelSIPModal] = useState(null);
    const [reasonList, setReasonList] = useState([]);
    const [executedList, setExecutedList] = useState([]);
    const [cancelledList, setCancelledList] = useState([]);
    const [showEditSIPModal, setShowEditSIPModal] = useState(null);
    const [showStepUpModal, setShowStepUpModal] = useState(null);
    const [pausedList, setPausedList] = useState([]);
    const [showRestartModal, setShowRestartModal] = useState(null);
    const [completedList, setCompletedList] = useState([]);
    const [showPauseSipModal, setShowPauseSipModal] = useState(null);

    useEffect(() => {
        _getReasonOptions();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            setActiveSipLoader(true);
            setSelectedSip({
                label: location.state.filter_label,
                value: location.state.filter
            })
            _activeList(location.state.filter);
        }
        // eslint-disable-next-line
    }, [location.state, sipTabs])

    useEffect(() => {
        if (location.state === null) {
            setActiveSipLoader(true);
            _activeList(selectedSip.value);
        }
        //eslint-disable-next-line
    }, [currentPage, , sipTabs]);

    useEffect(() => {
        if (showCancelSIPModal !== null) {
            if (reasonList.length !== 0) {
                const modal = new Modal(document.getElementById("cancel-sip"));
                modal.show();
            }
        }
        //eslint-disable-next-line
    }, [showCancelSIPModal]);

    useEffect(() => {
        if (showEditSIPModal !== null) {
            const modal = new Modal(document.getElementById("edit-sip"));
            modal.show();
        }
    }, [showEditSIPModal]);

    useEffect(() => {
        if (showStepUpModal !== null) {
            const modal = new Modal(document.getElementById("step-up-sip"));
            modal.show();
        }
    }, [showStepUpModal]);

    useEffect(() => {
        if (showRestartModal !== null) {
            const modal = new Modal(document.getElementById("edit-sip"));
            modal.show();
        }
    }, [showRestartModal]);

    useEffect(() => {
        if (showPauseSipModal !== null) {
            const modal = new Modal(document.getElementById("pause-sip"));
            modal.show();
        }
    }, [showPauseSipModal]);

    function _handleSipTabs(tab_index) {
        setSIPTabs(tab_index);
        setCurrentPage(1);
        setSelectedSip(SIP_OPTIONS[0]);
        setActiveExpandedRow({});
    }

    const _handleSelectBox = (value) => {
        setSelectedSip(value);
        setCurrentPage(1);
        setActiveExpandedRow({});
        _activeList(value.value)
    }

    const _handleRowClick = (row) => {
        setActiveExpandedRow(row);
    }

    // Handle page change
    const _handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber + 1);
        window.scrollTo(0, 0)
    };

    // API - Active sip
    const _activeList = (type) => {
        let url = "";
        switch (sipTabs) {
            case 1:
                url = "/sip/active-list"
                break;
            case 2:
                url = "/sip/processed-list"
                break;
            case 3:
                url = "/sip/cancelled-list"
                break;
            case 4:
                url = "/sip/paused-list"
                break;
            case 5:
                url = "/sip/completed-list"
                break;

            default:
                break;
        }
        const request = {
            "page_num": currentPage,
            "page_size": 12,
            "investment_type": type
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                switch (sipTabs) {
                    case 1:
                        setActiveSipList(response.data.sips);
                        break;
                    case 2:
                        setExecutedList(response.data.sips);
                        break;
                    case 3:
                        setCancelledList(response.data.sips);
                        break;
                    case 4:
                        setPausedList(response.data.sips);
                        break;
                    case 5:
                        setCompletedList(response.data.sips);
                        break;

                    default:
                        break;
                }

                setPageCount(response.data.total_page);
            } else {
                setActiveSipList([]);
                setExecutedList([]);
                setCancelledList([]);
                setPausedList([]);
                setCompletedList([]);
                setPageCount(0);
            }
            setActiveSipLoader(false);
        })
    }

    // API - Get reason options
    const _getReasonOptions = () => {
        const url = "/sip/cancel-reason";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const formatted_list = response.data.map((item) =>
                ({
                    value: item.id,
                    label: item.reason
                })
                );
                setReasonList(formatted_list);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
        });
    }

    const _handleEditActiveSip = (fund) => {
        setShowEditSIPModal(fund);
    }

    const _handleStepUpSip = (fund) => {
        setShowStepUpModal(fund);
    }

    const _handleRestartSip = (fund) => {
        setShowRestartModal(fund);
    }


    const _handleSaveStepup = (investment_type) => {
        setActiveSipLoader(true);
        setActiveExpandedRow({});
        _activeList(investment_type);
    }




    return (
        <Fragment>
            <CustomTabBar data={SIP_TABS}
                selectedTab={sipTabs}
                onSelectTab={_handleSipTabs}
                type={3} />
            <div className="d-flex flex-wrap gap-16px justify-content-between align-items-center border-top-1px border-bright-gray pt-4">
                <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0 ">{SIP_TABS.find(item => item.id === sipTabs).label} SIPs</p>

                <CustomSelectBox placeholder=""
                    className="w-200px"
                    value={selectedSip}
                    options={SIP_OPTIONS}
                    onSelectChange={_handleSelectBox} />
            </div>
            <div className="d-md-block d-none">
                {
                    sipTabs === 1 ?
                        <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                            {
                                activeSipLoader === true ?
                                    <Loader height="min-h-500px" />
                                    :
                                    activeSipList.length === 0 ?
                                        <EmptyScreen className="my-5"
                                            title="No SIPs"
                                            type={3}
                                            description="" />
                                        :
                                        <Fragment>
                                            <div className="mb-4 border-radius-tlr-16px d-md-block d-none">
                                                {
                                                    activeSipList.map((item, key) =>
                                                        <div className={`p-4 border-bottom-1px border-gainsboro cursor-pointer
                                                        ${activeExpandedRow.sip_id === item.sip_id && "e-bg-pastel-purple"}
                                                        ${key === 0 && "border-radius-tlr-16px"}`}
                                                            key={key}
                                                            onClick={() => _handleRowClick(item)}>
                                                            <div className={`row  
                                                            ${activeExpandedRow.sip_id === item.sip_id && "border-bottom-1px border-bright-gray pb-3"}
                                                            `}>
                                                                <div className="col-xl-5 col-5">

                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img src={item.icon}
                                                                            width={48}
                                                                            height={48}
                                                                            alt={item.name}
                                                                            draggable={false}
                                                                            className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="d-flex  gap-26px align-items-center">
                                                                        <div className="w-max-content">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.date_str}</p>
                                                                        </div>
                                                                        <img src={require("../../Assets/Images/Systematic-transaction/active-sip-arrow.png")}
                                                                            alt="Arrow"
                                                                            width={28}
                                                                            height={28}
                                                                            draggable={false} />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Next SIP due</p>
                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.next_sip_date}</p>
                                                                </div>
                                                                <div className="col">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.amount_text}</p>
                                                                </div>
                                                            </div>
                                                            {
                                                                activeExpandedRow.sip_id === item.sip_id &&
                                                                <div className="d-flex gap-16px justify-content-end pt-3 align-items-center  e-bg-pastel-purple ">
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-deep-carmine-pink mb-0 padding-10px-tb px-2"
                                                                        onClick={() => setShowCancelSIPModal(item)}>Cancel SIP</p>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-rebecca-purple mb-0 padding-10px-tb px-2"
                                                                        onClick={() => setShowPauseSipModal(item)}>Pause SIP</p>
                                                                    <SecondaryButton label="Step Up"
                                                                        className="padding-10px-tb padding-18px-lr e-bg-magnolia"
                                                                        onPress={() => _handleStepUpSip(item)} />
                                                                    <PrimaryButton label="Edit"
                                                                        className="padding-10px-tb px-4"
                                                                        onPress={() => _handleEditActiveSip(item)} />
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                                {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSipList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                            </div>
                                            <div className="mb-4 d-md-none d-block">
                                                {
                                                    activeSipList.map((item, key) => (
                                                        <div
                                                            key={key} // Move key to the outermost element
                                                            className={`p-4 border-bottom-1px border-bright-gray cursor-pointer `}>
                                                            <div className={`row `}>
                                                                <div className="col-12">
                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img
                                                                            src={item.icon}
                                                                            width={48}
                                                                            height={48}
                                                                            alt={item.name}
                                                                            draggable={false}
                                                                            className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain"
                                                                        />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                            {selectedSip.value === 1 && "Milestone: "}{item.name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center justify-content-between my-3">
                                                                        <div className="w-max-content">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.date_str}</p>
                                                                        </div>
                                                                        <img
                                                                            src={require("../../Assets/Images/Systematic-transaction/active-sip-arrow.png")}
                                                                            alt="Arrow"
                                                                            width={28}
                                                                            height={28}
                                                                            draggable={false}
                                                                        />
                                                                        <div>
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Next SIP due</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green">{item.next_sip_date}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between border-top-1px border-gainsboro pt-3">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ">Amount</p>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.amount_text}</p>
                                                                </div>
                                                                <div className="d-flex gap-16px flex-wrap justify-content-sm-end pt-3 ">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <ReactPaginate
                                                previousLabel={<PrevIcon />}
                                                nextLabel={<NextIcon />}
                                                breakLabel={<BreakLabel />}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={1}
                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                renderOnZeroPageCount={null}
                                                forcePage={currentPage - 1}
                                                containerClassName="e-pagination"
                                                activeClassName="e-pagination-active"
                                            />
                                        </Fragment>
                            }
                        </div>
                        :
                        sipTabs === 2 ?
                            // Executed tab
                            <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                {
                                    activeSipLoader === true ?
                                        <Loader height="min-h-500px" />
                                        :
                                        executedList.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No SIPs"
                                                type={3}
                                                description="" />
                                            :
                                            <Fragment>
                                                <div className="mb-4 border-radius-tlr-16px ">
                                                    {
                                                        executedList.map((item, key) =>
                                                            <div className={`p-4 border-bottom-1px border-bright-gray`}>
                                                                <div className={`row row-gap-16px`}
                                                                    key={key}>
                                                                    <div className="col-xl-6 col-lg-5 col-md-6">
                                                                        <div className="d-flex gap-8px align-items-center">
                                                                            <img src={item.icon}
                                                                                width={48}
                                                                                height={48}
                                                                                alt={item.name}
                                                                                draggable={false}
                                                                                className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <div className="w-max-content">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.amount_text}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Date</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.executed_date}</p>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Status</p>
                                                                        <div className="d-flex gap-4px align-items-center">
                                                                            <img src={item.status === "Successful" ? require("../../Assets/Images/Systematic-transaction/success.svg").default
                                                                                :
                                                                                require("../../Assets/Images/Systematic-transaction/failed.svg").default
                                                                            } alt={item.status}
                                                                                width={20}
                                                                                height={20}
                                                                                draggable={false} />
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${item.status === "Successful" ? "color-go-green" : "color-deep-carmine-pink"}`}>{item.status}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSipList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                </div>
                                                <ReactPaginate previousLabel={<PrevIcon />}
                                                    nextLabel={<NextIcon />}
                                                    breakLabel={<BreakLabel />}
                                                    pageCount={pageCount}
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={(value) => _handlePageChange(value.selected)}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={currentPage - 1}
                                                    containerClassName="e-pagination "
                                                    activeClassName="e-pagination-active" />
                                            </Fragment>
                                }
                            </div>
                            :
                            sipTabs === 3 ?
                                // Cancelled tab
                                <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                    {
                                        activeSipLoader === true ?
                                            <Loader height="min-h-500px" />
                                            :
                                            cancelledList.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No SIPs"
                                                    type={3}
                                                    description="" />
                                                :
                                                <Fragment>
                                                    <div className="mb-4 border-radius-tlr-16px ">
                                                        {
                                                            cancelledList.map((item, key) =>
                                                                <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer
                                                        ${activeExpandedRow.sip_id === item.sip_id && width > 768 && "e-bg-pastel-purple"}
                                                        ${key === 0 && "border-radius-tlr-16px"}`}
                                                                    key={key}
                                                                    onClick={() => _handleRowClick(item)}
                                                                >
                                                                    <div className={`row row-gap-16px 
                                                            ${activeExpandedRow.sip_id === item.sip_id && "border-bottom-1px border-bright-gray pb-3"}
                                                            `}>
                                                                        <div className="col-xl-5">
                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                <img src={item.icon}
                                                                                    width={48}
                                                                                    height={48}
                                                                                    alt={item.name}
                                                                                    draggable={false}
                                                                                    className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <div className="w-max-content">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.amount_text}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Created in</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.sip_date}</p>
                                                                        </div>
                                                                        <div className="col-md col-6">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Cancelled on</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-deep-carmine-pink">{item.cancelled}</p>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        width > 768 ?
                                                                            activeExpandedRow.sip_id === item.sip_id &&
                                                                            <div className={`d-flex justify-content-end pt-3 e-bg-pastel-purple`}>
                                                                                <PrimaryButton label="Restart SIP"
                                                                                    className="padding-10px-tb px-4"
                                                                                    onPress={() => _handleRestartSip(item)} />
                                                                            </div>
                                                                            :
                                                                            <div className={`d-flex justify-content-end pt-3 `}>
                                                                                <PrimaryButton label="Restart SIP"
                                                                                    className="padding-10px-tb px-4"
                                                                                    onPress={() => _handleRestartSip(item)} />
                                                                            </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSipList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                    </div>
                                                    <ReactPaginate previousLabel={<PrevIcon />}
                                                        nextLabel={<NextIcon />}
                                                        breakLabel={<BreakLabel />}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(value) => _handlePageChange(value.selected)}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                        containerClassName="e-pagination "
                                                        activeClassName="e-pagination-active" />
                                                </Fragment>
                                    }
                                </div>
                                :
                                sipTabs === 4 ?
                                    // Paused tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeSipLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                pausedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No SIPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                pausedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer
                                                        ${activeExpandedRow.sip_id === item.sip_id && width > 768 && "e-bg-pastel-purple"}
                                                        ${key === 0 && "border-radius-tlr-16px"}`}

                                                                        key={key}
                                                                        onClick={() => _handleRowClick(item)}
                                                                    >
                                                                        <div className={`row  row-gap-16px
                                                            ${activeExpandedRow.sip_id === item.sip_id && "border-bottom-1px border-bright-gray pb-3"}
                                                            `}>
                                                                            <div className="col-xl-4">
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <div className="w-max-content">
                                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.amount_text}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Created in</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.sip_date}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Paused on</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-cyan-cornflower-blue">{item.paused}</p>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            width > 768 ?
                                                                                activeExpandedRow.sip_id === item.sip_id &&
                                                                                <div className="d-flex justify-content-end pt-3  e-bg-pastel-purple ">
                                                                                    <PrimaryButton label="Resume SIP"
                                                                                        className="padding-10px-tb px-4"
                                                                                        onPress={() => _handleRestartSip(item)} />
                                                                                </div>
                                                                                :
                                                                                <div className="d-flex justify-content-end pt-3 border-top-1px border-gainsboro mt-3 ">
                                                                                    <PrimaryButton label="Resume SIP"
                                                                                        className="padding-10px-tb px-4"
                                                                                        onPress={() => _handleRestartSip(item)} />
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSipList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>
                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                                    :
                                    // Completed tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeSipLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                completedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No SIPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                completedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray`}
                                                                        key={key}>
                                                                        <div className={`row row-gap-16px`}>
                                                                            <div className="col-md-5">
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <div className="w-max-content">
                                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.amount_text}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Created in</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.created}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.sip_date}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Completed on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.completed_on}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                        data={activeSipList}
                                                        customStyles={TABLE_STYLE}
                                                        expandableRows={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                        rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>
                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                }
            </div>
            <div className="d-md-none d-block">
                {
                    sipTabs === 1 ?
                        <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                            {

                                activeSipLoader === true ?
                                    <Loader height="min-h-500px" />

                                    :
                                    activeSipList.length === 0 ?

                                        <EmptyScreen className="my-5"
                                            title="No SIPs"
                                            type={3}
                                            description="" />

                                        :
                                        <Fragment>

                                            <div className="mb-4 d-md-none d-block">
                                                {
                                                    activeSipList.map((item, key) => (
                                                        <div
                                                            key={key} // Move key to the outermost element
                                                            className={`p-4 border-bottom-1px border-bright-gray cursor-pointer `}>
                                                            <div className={`row `}>
                                                                <div className="col-12 d-flex align-items-center justify-content-between gap-16px">
                                                                    <div className="d-flex gap-8px align-items-center">
                                                                        <img src={item.icon}
                                                                            width={48}
                                                                            height={48}
                                                                            alt={item.name}
                                                                            draggable={false}
                                                                            className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain"
                                                                        />
                                                                        <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">
                                                                            {selectedSip.value === 1 && "Milestone: "}{item.name}
                                                                        </p>
                                                                    </div>
                                                                    <Popup
                                                                        trigger={
                                                                            <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                alt="More"
                                                                                draggable={false}
                                                                                width={24}
                                                                                height={24}
                                                                                className="cursor-pointer" />
                                                                        }
                                                                        position="bottom right"
                                                                        closeOnDocumentClick
                                                                    >
                                                                        <div className="d-flex flex-column gap-12px justify-content-end bg-white p-2 e-ranking-category">
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => _handleEditActiveSip(item)}>Edit</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => _handleStepUpSip(item)}>Step Up</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => setShowPauseSipModal(item)}>Pause SIP</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0"
                                                                                onClick={() => setShowCancelSIPModal(item)}>Cancel SIP</p>
                                                                        </div>
                                                                    </Popup>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="d-flex align-items-center justify-content-between my-3">
                                                                        <div className="w-max-content">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.date_str}</p>
                                                                        </div>
                                                                        <img
                                                                            src={require("../../Assets/Images/Systematic-transaction/active-sip-arrow.png")}
                                                                            alt="Arrow"
                                                                            width={28}
                                                                            height={28}
                                                                            draggable={false}
                                                                        />
                                                                        <div>
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Next SIP due</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green">{item.next_sip_date}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex justify-content-between border-top-1px border-gainsboro pt-3">
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space ">Amount</p>
                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0">{item.amount_text}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>

                                            <ReactPaginate
                                                previousLabel={<PrevIcon />}
                                                nextLabel={<NextIcon />}
                                                breakLabel={<BreakLabel />}
                                                pageCount={pageCount}
                                                pageRangeDisplayed={2}
                                                marginPagesDisplayed={1}
                                                onPageChange={(value) => _handlePageChange(value.selected)}
                                                renderOnZeroPageCount={null}
                                                forcePage={currentPage - 1}
                                                containerClassName="e-pagination"
                                                activeClassName="e-pagination-active"
                                            />
                                        </Fragment>
                            }
                        </div>

                        :
                        sipTabs === 2 ?
                            // Executed tab
                            <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                {
                                    activeSipLoader === true ?
                                        <Loader height="min-h-500px" />
                                        :
                                        executedList.length === 0 ?
                                            <EmptyScreen className="my-5"
                                                title="No SIPs"
                                                type={3}
                                                description="" />
                                            :
                                            <Fragment>
                                                <div className="mb-4 border-radius-tlr-16px ">
                                                    {
                                                        executedList.map((item, key) =>
                                                            <div className={`p-4 border-bottom-1px border-bright-gray`}>
                                                                <div className={`row row-gap-16px`}
                                                                    key={key}>
                                                                    <div className="col-md-6">
                                                                        <div className="d-flex gap-8px align-items-center">
                                                                            <img src={item.icon}
                                                                                width={48}
                                                                                height={48}
                                                                                alt={item.name}
                                                                                draggable={false}
                                                                                className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                            <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm col-6">
                                                                        <div className="w-max-content">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.amount_text}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm col-6">
                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Date</p>
                                                                        <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.executed_date}</p>

                                                                    </div>
                                                                    <div className="col-sm col-6">

                                                                        <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Status</p>
                                                                        <div className="d-flex gap-4px align-items-center">
                                                                            <img src={item.status === "Successful" ? require("../../Assets/Images/Systematic-transaction/success.svg").default
                                                                                :
                                                                                require("../../Assets/Images/Systematic-transaction/failed.svg").default
                                                                            } alt={item.status}
                                                                                width={20}
                                                                                height={20}
                                                                                draggable={false} />
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${item.status === "Successful" ? "color-go-green" : "color-deep-carmine-pink"}`}>{item.status}</p>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                    {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSipList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                </div>

                                                <ReactPaginate previousLabel={<PrevIcon />}
                                                    nextLabel={<NextIcon />}
                                                    breakLabel={<BreakLabel />}
                                                    pageCount={pageCount}
                                                    pageRangeDisplayed={2}
                                                    marginPagesDisplayed={1}
                                                    onPageChange={(value) => _handlePageChange(value.selected)}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={currentPage - 1}
                                                    containerClassName="e-pagination "
                                                    activeClassName="e-pagination-active" />
                                            </Fragment>

                                }

                            </div>
                            :
                            sipTabs === 3 ?
                                // Cancelled tab
                                <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                    {
                                        activeSipLoader === true ?
                                            <Loader height="min-h-500px" />
                                            :
                                            cancelledList.length === 0 ?
                                                <EmptyScreen className="my-5"
                                                    title="No SIPs"
                                                    type={3}
                                                    description="" />
                                                :
                                                <Fragment>
                                                    <div className="mb-4 border-radius-tlr-16px ">
                                                        {
                                                            cancelledList.map((item, key) =>
                                                                <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer
                                                        ${key === 0 && "border-radius-tlr-16px"}`}
                                                                    key={key}>
                                                                    <div className={`row row-gap-16px `}>
                                                                        <div className="col-md-4 d-flex align-items-center gap-16px justify-content-between">
                                                                            <div className="d-flex gap-8px align-items-center">
                                                                                <img src={item.icon}
                                                                                    width={48}
                                                                                    height={48}
                                                                                    alt={item.name}
                                                                                    draggable={false}
                                                                                    className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                                <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                            </div>
                                                                            <Popup
                                                                                trigger={
                                                                                    <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                        alt="More"
                                                                                        draggable={false}
                                                                                        width={24}
                                                                                        height={24}
                                                                                        className="cursor-pointer" />
                                                                                }
                                                                                position="bottom right"
                                                                                closeOnDocumentClick
                                                                            >
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0 bg-white p-2 e-ranking-category"
                                                                                    onClick={() => _handleRestartSip(item)}>Restart SIP</p>
                                                                            </Popup>
                                                                        </div>

                                                                        <div className="col-md col-6">
                                                                            <div className="w-max-content">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.amount_text}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md col-6">
                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Created in</p>
                                                                            <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>

                                                                        </div>
                                                                        <div className="col-md col-6">

                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.sip_date}</p>

                                                                        </div>
                                                                        <div className="col-md col-6">

                                                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Cancelled on</p>
                                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-deep-carmine-pink">{item.cancelled}</p>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            )
                                                        }
                                                        {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSipList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                    </div>

                                                    <ReactPaginate previousLabel={<PrevIcon />}
                                                        nextLabel={<NextIcon />}
                                                        breakLabel={<BreakLabel />}
                                                        pageCount={pageCount}
                                                        pageRangeDisplayed={2}
                                                        marginPagesDisplayed={1}
                                                        onPageChange={(value) => _handlePageChange(value.selected)}
                                                        renderOnZeroPageCount={null}
                                                        forcePage={currentPage - 1}
                                                        containerClassName="e-pagination "
                                                        activeClassName="e-pagination-active" />
                                                </Fragment>

                                    }

                                </div>
                                :
                                sipTabs === 4 ?
                                    // Paused tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeSipLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                pausedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No SIPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                pausedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray cursor-pointer
                                                                    ${key === 0 && "border-radius-tlr-16px"}`}
                                                                        key={key}
                                                                        onClick={() => _handleRowClick(item)}

                                                                    >
                                                                        <div className={`row  row-gap-16px`}>
                                                                            <div className="col-md-4 d-flex align-items-center justify-content-between gap-16px">
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                </div>
                                                                                <Popup
                                                                                    trigger={
                                                                                        <img src={require("../../Assets/Images/Systematic-transaction/more.png")}
                                                                                            alt="More"
                                                                                            draggable={false}
                                                                                            width={24}
                                                                                            height={24}
                                                                                            className="cursor-pointer" />
                                                                                    }
                                                                                    position="bottom right"
                                                                                    closeOnDocumentClick
                                                                                >
                                                                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black cursor-pointer mb-0 bg-white p-2 e-ranking-category"
                                                                                        onClick={() => _handleRestartSip(item)}>Resume SIP</p>
                                                                                </Popup>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <div className="w-max-content">
                                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.amount_text}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Created in</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{item.created}</p>

                                                                            </div>
                                                                            <div className="col-md col-6">

                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">{item.sip_date}</p>

                                                                            </div>
                                                                            <div className="col-md col-6">

                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Paused on</p>
                                                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-cyan-cornflower-blue">{item.paused}</p>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                    data={activeSipList}
                                                    customStyles={TABLE_STYLE}
                                                    expandableRows={true}
                                                    expandableRowsComponent={ExpandedComponent}
                                                    rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>

                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>

                                        }

                                    </div>
                                    :
                                    // Completed tab
                                    <div className="border-1px border-gainsboro border-radius-16px mt-4 ">
                                        {
                                            activeSipLoader === true ?
                                                <Loader height="min-h-500px" />
                                                :
                                                completedList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No SIPs"
                                                        type={3}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-tlr-16px ">
                                                            {
                                                                completedList.map((item, key) =>
                                                                    <div className={`p-4 border-bottom-1px border-bright-gray`}
                                                                        key={key}>
                                                                        <div className={`row row-gap-16px`}>
                                                                            <div className="col-md-5">
                                                                                <div className="d-flex gap-8px align-items-center">
                                                                                    <img src={item.icon}
                                                                                        width={48}
                                                                                        height={48}
                                                                                        alt={item.name}
                                                                                        draggable={false}
                                                                                        className="border-radius-12px e-fund-detail-chart bg-white object-fit-contain" />
                                                                                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{item.name}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <div className="w-max-content">
                                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                                                                                    <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0`}>{item.amount_text}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Created in</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.created}</p>

                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">SIP date</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 coloreerie-black`}>{item.sip_date}</p>
                                                                            </div>
                                                                            <div className="col-md col-6">
                                                                                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Completed on</p>
                                                                                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-ufo-green`}>{item.completed_on}</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            }
                                                            {/* <DataTableContainer columns={TABLE_COLUMNS}
                                                        data={activeSipList}
                                                        customStyles={TABLE_STYLE}
                                                        expandableRows={true}
                                                        expandableRowsComponent={ExpandedComponent}
                                                        rowClick={(row) => _handleRowClick(row)} /> */}
                                                        </div>

                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>

                                        }

                                    </div>
                }
            </div>

            {
                showCancelSIPModal !== null &&
                <CancelSIPModal closeModal={() => setShowCancelSIPModal(null)}
                    options={reasonList}
                    id={showCancelSIPModal.sip_id}
                    success={() => _activeList(selectedSip.value)} />
            }
            {
                showEditSIPModal !== null &&
                <EditSIP fund={showEditSIPModal}
                    filterType={selectedSip}
                    header="Edit SIP"
                    type={1}
                    buttonLabel="Save"
                    closeModal={() => _handleEditActiveSip(null)}
                    success={_handleSaveStepup} />
            }

            {
                showRestartModal !== null &&
                <EditSIP fund={showRestartModal}
                    filterType={selectedSip}
                    header={sipTabs === 3 ? "Restart SIP" : "Resume SIP"}
                    type={3}
                    buttonLabel={sipTabs === 3 ? "Restart SIP" : "Resume SIP"}
                    closeModal={() => _handleRestartSip(null)}
                    success={_handleSaveStepup} />
            }


            {showStepUpModal !== null &&
                <StepUpSIP fund={showStepUpModal}
                    filterType={selectedSip}
                    closeModal={() => _handleStepUpSip(null)}
                    success={_handleSaveStepup} />
            }

            {
                showPauseSipModal !== null &&
                <PauseSIPModal close={() => setShowPauseSipModal(null)}
                    id={showPauseSipModal.sip_id}
                    confirm={() => _activeList(selectedSip.value)} />
            }
        </Fragment>
    )
}

export default SIP;