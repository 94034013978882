
/*
 *   File : update-financial-status.js
 *   Author URI : https://evoqins.com
 *   Description : Update financial status modal 
 *   Integrations : null
 *   Version : v1.1
 */

// import packages
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// import components
import { Icon } from "../Icon";
import { CustomAmountInput } from "../FormElements";
import { PrimaryButton } from "../Buttons";

// import custom styles
import style from "../../Styles/Components/investment.module.scss";
import Color from '../../Styles/color.module.scss';

// import services
import APIService from "../../Services/api-service";

const UpdateFinancialStatusModal = React.memo((props) => {

    const [summary, setSummary] = useState({});

    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        setSummary(props.summary);
        if (props.summary.value !== null) {
            setAmount(props.summary.value);
        }
    }, [props.summary])

    useEffect(() => {
        var my_modal = document.getElementById("update-financial-status");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    function _handleTooltip(status) {
        setShowInfo(status)
    }

    // handle amount
    function _handleAmount(input_value) {
        setAmount(input_value);
        setAmountError("");
    }

    // submit amount
    function _submitAmount() {
        let valid = true;
        if (amount === "") {
            setAmountError("Amount is required");
            valid = false;
        }
        if (valid === true) {
            _updateFinancialStatus()
        }
    }

    // API - update financial status
    const _updateFinancialStatus = () => {
        setApiLoader(true);

        const url = "/home/upsert-financial-status";

        const request = {
            "item_id": summary.item_id,
            "value": amount
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.statusUpdated(amount);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    }

    return (
        <div className={`modal fade ${style.e_investment}`}
            id="update-financial-status"
            tabIndex="-1"
            aria-labelledby="update-financial-status"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content pb-4 ${style.e_content} `}>
                    {/* modal title */}
                    <div className="border-bottom-1px border-ash-gray px-4">
                        <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                            <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                {props.heading}
                            </h6>
                            <Icon icon="close-btn"
                                size={24}
                                color={Color.eerie_black}
                                id="close-modal"
                                className="cursor-pointer"
                                data-bs-dismiss="modal" />
                        </div>
                    </div>

                    {/* amount input for fund */}
                    <div className="px-4 pt-3">
                        <h6 className="e-font-14 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-20">{props.title}</h6>
                        <div className="box-shadow-black p-3 border-radius-16px mb-3">
                            <div className="d-flex justify-content-between align-items-center position-relative">
                                <div className="d-block">
                                    {
                                        !props.blueLabel &&
                                        <p className="e-font-12 mb-2 color-black e-montserrat-medium e-line-height-16">Asset class</p>
                                    }
                                    <div className="d-flex align-items-center gap-8px e-font-14 color-eerie-black e-montserrat-medium e-line-height-20">
                                        <img src={summary.image} alt={summary.name}
                                            draggable={false}
                                            width={38} />
                                        {summary.name}
                                    </div>
                                </div>
                                <Icon icon="info-circle"
                                    size={20}
                                    className="cursor-pointer"
                                    onMouseEnter={() => _handleTooltip(true)}
                                    onMouseLeave={() => _handleTooltip(false)} />
                                {
                                    showInfo === true &&
                                    <div className="position-absolute end-0 top-48px box-shadow-black p-2 border-radius-8px bg-white z-index-3">
                                        <p className="e-font-12  color-black e-montserrat-medium e-line-height-16 mb-0">
                                            {props.tooltipLabel}
                                        </p>
                                    </div>
                                }
                            </div>
                        </div>
                        <CustomAmountInput value={amount}
                            error={amountError}
                            postfix="*"
                            label={props.inputLabel}
                            placeholder="5,00,000"
                            is_zero={true}
                            handleChange={_handleAmount}
                        />
                        {/* {
                            props.blueLabel &&
                            <div className="d-flex align-items-start padding-12px-all mt-2 e-bg-light-blue border-radius-12px mt-4 color-ocean-blue e-font-14 e-line-height-20 gap-8px e-montserrat-medium">
                                <Icon icon="info-circle"
                                    size={20} />
                                {props.blueLabel}
                            </div>
                        } */}
                        <div className="row mt-4">
                            <div className="col-12 mt-2">
                                <PrimaryButton label="Save"
                                    disabled={apiLoader}
                                    showLoader={apiLoader}
                                    className="padding-12px-tb w-100"
                                    onPress={_submitAmount} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default UpdateFinancialStatusModal;