
/*
*   File : list.js
*   Author : https://evoqins.com
*   Description : Order list
*   Version : 1.0.0
*/


// import packages
import React, { useState, useEffect, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";

// import components
import { Breadcrumb, EmptyScreen, Loader, SectionTitle } from "../../Components/Other"
import { Header } from "../../Components/Header";
import { CustomTabBar } from "../../Components/Tab";
import { Icon } from "../../Components/Icon";
import { DataTableContainer } from "../../Components/Table";
import { OrderFilter } from "../../Components/Card";

// import services
import APIService from "../../Services/api-service";
import { _formatDate, _getOrderStatus } from "../../Helper/helper";

// import style
import Color from "../../Styles/color.module.scss";



const INVESTMENT_CATEGORY = [
    {
        label: "Milestone",
        id: 1
    },

    {
        label: "Basket",
        id: 3
    },
    {
        label: "Individual Funds",
        id: 2
    },
]

const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },
    head: {
        style: {
            height: "0px"
        }
    },
    headRow: {
        style: {
            borderBottomColor: 'transparent',

        }
    },
    rows: {
        style: {
            cursor: "pointer",
            '&:not(:last-child)': {
                borderBottom: `1px solid ${Color.gainsboro}`,
            },
            '&:hover': {
                '& .e-row-title': {
                    color: Color.rebecca_purple
                },
                backgroundColor: '#FAF5FF'

            },
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px',
            paddingTop: '22px',
            paddingBottom: '22px',
            '&:first-child': {
                paddingLeft: "22px",
                paddingTop: '22px',
                paddingBottom: '22px',
            }

        }
    }

}


const NextIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple} />
    )
}

const PrevIcon = () => {
    return (
        <Icon icon='Arrow-Right'
            width="24px"
            height="24px"
            color={Color.rebecca_purple}
            className="e-transform-left-arrow" />
    )
}

const BreakLabel = () => {
    return (
        <span>...</span>
    )
}

const OrderList = () => {
    const location = useLocation();
    const navigate = useNavigate();


    const [screenLoader, setScreenLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);

    const [paymentTypes, setPaymentTypes] = useState([]);
    const [orderTypes, setOrderTypes] = useState([]);
    const [orderStatus, setOrderStatus] = useState([]);

    const [selectedPaymentTypes, setSelectedPaymentTypes] = useState([]);
    const [selectedOrderTypes, setSelectedOrderTypes] = useState([]);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);
    const [startDate, setStartDate] = useState("DD-MM-YYYY");
    const [endDate, setEndDate] = useState("DD-MM-YYYY");

    const [orderList, setOrderList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1); // Current page
    // eslint-disable-next-line
    const [pageCount, setPageCount] = useState(0); //total number of pages

    const [investment, setInvestment] = useState(INVESTMENT_CATEGORY[0].id);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [filterEnabled, setFilterEnabled] = useState(false);

    useEffect(() => {
        _getFilterData();
    }, []);

    useEffect(() => {
        if (location.state === null) {
            _orderList(selectedPaymentTypes, selectedOrderStatus, selectedOrderTypes, null, null, 1, 1);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            _orderList(location.state.paymentTypes, location.state.orderStatus, location.state.orderTypes, location.state.startDate, location.state.endDate, location.state.type, location.state.page);
            setSelectedPaymentTypes(location.state.paymentTypes);
            setSelectedOrderStatus(location.state.orderStatus);
            setSelectedOrderTypes(location.state.orderTypes);
            setStartDate(location.state.startDate);
            setEndDate(location.state.endDate);
        }
        //eslint-disable-next-line
    }, [location]);


    function _navigatePreviousPage() {
        navigate("/");
    }

    // handle select payment types
    function _handlePaymentTypes(selected_list) {
        setSelectedPaymentTypes(selected_list);
    }

    // handle select order status
    function _handleOrderStatus(selected_list) {
        setSelectedOrderStatus(selected_list);
    }

    // handle select order type
    function _handleOrderTypes(selected_list) {
        setSelectedOrderTypes(selected_list);
    }

    // handle start date
    function _handleStart(selected_date) {
        const formatted_start_date = _formatDate(selected_date);
        setStartDate(formatted_start_date);

        // Parse the formatted start date into a comparable Date object
        const formattedStartDateObj = new Date(
            formatted_start_date.split('-').reverse().join('-')
        );

        // Parse endDate only if it is not the default "DD-MM-YYYY"
        if (endDate !== "DD-MM-YYYY") {
            const endDateObj = new Date(endDate.split('-').reverse().join('-'));

            // Reset the end date if it's before the new start date
            if (formattedStartDateObj > endDateObj) {
                setEndDate("DD-MM-YYYY");
            }
        }
    }

    // handle end date
    function _handleEnd(selected_date) {
        const formatted_start_date = _formatDate(selected_date);
        setEndDate(formatted_start_date);
    }

    // handle investment category
    function _handleInvestment(tab_index) {
        setInvestment(tab_index);
        setCurrentPage(1);
        _orderList([], [], [], null, null, tab_index);
        navigate("/orders");
    }


    // handle navigate to detail
    function _handleRowClick(row) {
        navigate("/orders", {
            state: {
                id: row.order_id,
                type: investment,
                paymentTypes: selectedPaymentTypes,
                orderTypes: selectedOrderTypes,
                orderStatus: selectedOrderStatus,
                startDate: startDate,
                endDate: endDate,
                page: currentPage
            }
        })
        if (investment === 1) {
            navigate("goal-order", {
                state: {
                    id: row.order_id,
                    type: investment,
                    paymentTypes: selectedPaymentTypes,
                    orderTypes: selectedOrderTypes,
                    orderStatus: selectedOrderStatus,
                    startDate: startDate,
                    endDate: endDate,
                    page: currentPage
                }
            })
        } else if (investment === 2) {
            navigate("fund-order", {
                state: {
                    id: row.order_id,
                    type: investment,
                    paymentTypes: selectedPaymentTypes,
                    orderTypes: selectedOrderTypes,
                    orderStatus: selectedOrderStatus,
                    startDate: startDate,
                    endDate: endDate,
                    page: currentPage
                }
            })
        }
        else {
            navigate("goal-order", {
                state: {
                    id: row.order_id,
                    type: investment,
                    paymentTypes: selectedPaymentTypes,
                    orderTypes: selectedOrderTypes,
                    orderStatus: selectedOrderStatus,
                    startDate: startDate,
                    endDate: endDate,
                    page: currentPage
                }
            })
        }
    }

    // handle page 
    function _handlePageChange(pageNumber) {
        setCurrentPage(pageNumber + 1);
        _orderList(selectedPaymentTypes, selectedOrderStatus, selectedOrderTypes, startDate, endDate, investment, pageNumber + 1);
        window.scrollTo(0, 0);
        navigate("/orders");
    };

    // API - get filter data 
    const _getFilterData = () => {
        const url = "/order/filter-data";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                setPaymentTypes(response.data.payment_status);
                setOrderTypes(response.data.order_type);
                setOrderStatus(response.data.order_status);
            }
            setScreenLoader(false);
        })
    }

    // API - get order list
    const _orderList = (payment_types = [], order_status = [], order_types = [], start_date = null, end_date = null, type = 1, page = 1) => {
        setApiLoader(true);
        setInvestment(type);
        setSelectedPaymentTypes(payment_types);
        setSelectedOrderStatus(order_status);
        setSelectedOrderTypes(order_types);
        setStartDate(start_date !== null ? start_date : "DD-MM-YYYY");
        setEndDate(end_date !== null ? end_date : "DD-MM-YYYY");
        setCurrentPage(page);
        setShowFilterModal(false);

        document.body.style.overflow = 'unset';

        const url = "/order/list-order";

        const request = {
            "investment_type": type,
            "page_num": page,
            "page_size": 10,
            "payment_status": payment_types,
            "order_status": order_status,
            "type": order_types,
            "from_date": start_date !== "DD-MM-YYYY" ? start_date : null,
            "to_date": end_date !== "DD-MM-YYYY" ? end_date : null,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setOrderList(response.data.orders);
                const no_of_pages = Math.ceil(response.data.total_orders / 10);
                setPageCount(no_of_pages)
            } else {
                setOrderList([]);
            }
            if (payment_types.length > 0 || order_status.length > 0 || order_types.length > 0 || (start_date !== null && end_date !== null)) {
                setFilterEnabled(true)
            } else {
                setFilterEnabled(false);
            }
            setApiLoader(false);
        });
    }

    const _openFilterModal = () => {
        setShowFilterModal(true);
        document.body.style.overflow = 'hidden';
    }


    const ORDER_COLUMNS = [

        {
            cell: row => <div className="d-flex  gap-8px"
                onClick={() => _handleRowClick(row)}>
                <img src={row.icon}
                    width={48}
                    height={48}
                    alt={row.scheme_name}
                    draggable={false}
                    className="border-radius-12px e-fund-detail-chart object-fit-contain bg-white" />
                <div className="d-flex gap-8px flex-column">
                    <p className="color-eerie-black e-font-14 e-line-height-20 e-montserrat-semi-bold mb-0 e-row-title">{investment === 1 && "Milestone: "}{row.name}</p>
                    <div className="d-flex flex-wrap align-items-center color-outer-space e-font-12 e-line-height-16 e-montserrat-regular">
                        <span className="e-bg-lavender-frost padding-6px-tb px-2 color-rebecca-purple e-font-12 e-line-height-14 e-montserrat-medium border-radius-30px me-3">{row.order_no}</span>
                        Date: <span className="color-eerie-black e-montserrat-semi-bold ps-1">{row.order_date}</span>
                    </div>
                </div>
            </div>,
            sortable: false,
            width: '420px'
        },
        {
            cell: row => <div onClick={() => _handleRowClick(row)}>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Amount</p>
                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{row.amount_text}</p>
            </div>,
            sortable: false,
            width: "150px"
        },
        {
            cell: row => <div onClick={() => _handleRowClick(row)}>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Order Type</p>
                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black`}>{row.type}</p>
            </div>,
            sortable: false,
            width: "150px"
        },
        {
            cell: row => <div onClick={() => _handleRowClick(row)}>
                <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space">Order Status</p>
                <p className={`e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 ${_getOrderStatus(row.order_status).color}`}>
                    <img src={_getOrderStatus(row.order_status).icon}
                        alt="Success"
                        draggable={false}
                        className="me-1" />
                    {row.order_status}
                </p>
            </div>,
            sortable: false,
            width: "180px"
        },
        {
            cell: row => <Icon icon="arrow-right" size={20} />,
            width: "50px"
        }

    ]
    return (
        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <Fragment>
                <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                    <Header type={2} />
                    <div className="row ">
                        <div className="col-lg-11 padding-144px-bottom">
                            <section className="px-4 ">
                                <Breadcrumb mainPage="Home"
                                    currentPage="Orders"
                                    type={1}
                                    handleNavigate={_navigatePreviousPage} />


                                <SectionTitle label="Orders"
                                    className="e-line-height-38 margin-30px-top d-lg-block d-none" />

                                <div className="d-lg-none d-flex justify-content-between align-items-center">
                                    <SectionTitle label="Orders"
                                        className="e-line-height-38 margin-30px-top" />

                                    <div className='position-relative cursor-pointer ' onClick={_openFilterModal}>
                                        <img src={require("../../Assets/Images/Global/filter.png")}
                                            width={24}
                                            height={24}
                                            alt="Filter"
                                        />

                                        {
                                            filterEnabled === true &&
                                            <span className="position-absolute top-2px end-0 rounded-pill e-bg-crimpson-red e-font-10 e-line-height-12 color-white e-montserrat-bold w-10px h-10px padding-1px-top d-flex align-items-center justify-content-center">
                                            </span>
                                        }


                                    </div>
                                </div>


                                <div className="row margin-12px-top">

                                    {/* Order filter */}
                                    <div className="col-xl-3 col-lg-4 d-lg-block d-none">
                                        <OrderFilter
                                            paymentTypes={paymentTypes}
                                            orderTypes={orderTypes}
                                            orderStatus={orderStatus}
                                            startDate={startDate}
                                            endDate={endDate}
                                            selectedPaymentTypes={selectedPaymentTypes}
                                            selectedOrderTypes={selectedOrderTypes}
                                            selectedOrderStatus={selectedOrderStatus}
                                            investment={investment}
                                            handlePaymentTypes={_handlePaymentTypes}
                                            handleOrderStatus={_handleOrderStatus}
                                            handleOrderTypes={_handleOrderTypes}
                                            handleStart={_handleStart}
                                            handleEnd={_handleEnd}
                                            getOrderList={(payment_types, order_status, order_types, start_date, end_date, type, page) => {
                                                _orderList(payment_types, order_status, order_types, start_date, end_date, type, page);
                                                window.scrollTo(0, 0);
                                                navigate("/orders");
                                            }} />
                                    </div>


                                    <div className="col-xl-9 col-lg-8 pt-lg-0 pt-1">
                                        <div className="border-bottom-1px border-onyx">
                                            <CustomTabBar selectedTab={investment}
                                                data={INVESTMENT_CATEGORY}
                                                onSelectTab={_handleInvestment} />
                                        </div>
                                        <h6 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black margin-28px-tb">Recent Orders</h6>
                                        {
                                            apiLoader === true ?
                                                <Loader height="h-80vh" />
                                                :
                                                orderList.length === 0 ?
                                                    <EmptyScreen className="my-5"
                                                        title="No orders found"
                                                        type={2}
                                                        description="" />
                                                    :
                                                    <Fragment>
                                                        <div className="mb-4 border-radius-24px box-shadow-black">
                                                            <DataTableContainer columns={ORDER_COLUMNS}
                                                                data={orderList}
                                                                customStyles={TABLE_STYLE}
                                                                rowClick={(row) => _handleRowClick(row)} />
                                                        </div>

                                                        <ReactPaginate previousLabel={<PrevIcon />}
                                                            nextLabel={<NextIcon />}
                                                            breakLabel={<BreakLabel />}
                                                            pageCount={pageCount}
                                                            pageRangeDisplayed={2}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={(value) => _handlePageChange(value.selected)}
                                                            renderOnZeroPageCount={null}
                                                            forcePage={currentPage - 1}
                                                            containerClassName="e-pagination "
                                                            activeClassName="e-pagination-active" />
                                                    </Fragment>
                                        }
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                </div >
                {
                    showFilterModal === true &&
                    <OrderFilter modal={true}
                        paymentTypes={paymentTypes}
                        orderTypes={orderTypes}
                        orderStatus={orderStatus}
                        startDate={startDate}
                        endDate={endDate}
                        selectedPaymentTypes={selectedPaymentTypes}
                        selectedOrderTypes={selectedOrderTypes}
                        selectedOrderStatus={selectedOrderStatus}
                        investment={investment}
                        handlePaymentTypes={_handlePaymentTypes}
                        handleOrderStatus={_handleOrderStatus}
                        handleOrderTypes={_handleOrderTypes}
                        handleStart={_handleStart}
                        handleEnd={_handleEnd}
                        getOrderList={(payment_types, order_status, order_types, start_date, end_date, type, page) => {
                            _orderList(payment_types, order_status, order_types, start_date, end_date, type, page);
                            window.scrollTo(0, 0);
                            navigate("/orders");
                        }} />
                }
            </Fragment >

    )
}

export default OrderList