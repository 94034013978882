/*
*   File : nominee-detail.js
*   Author : https://evoqins.com
*   Description : Screen to fill the Nominees
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { differenceInYears } from "date-fns";
import { toast } from "react-toastify";

// import components
import { Icon } from "../../../Components/Icon";
import { PrimaryButton } from "../../../Components/Buttons";
import { StepProgress } from "../../../Components/Other";
import { KYCProgressCard } from "../../../Components/Card";
import { RadioGroup } from "../../../Components/FormElements";
import { KYCErrorCard, NomineeCard } from "../../../Components/Cards";
import { EsignCreateModal, KYCConfirmationModal, NomineeConfirmationModal, } from "../../../Components/Modal";

// services
import APIService from "../../../Services/api-service";
import { _getSignupProgress } from "../../../Helper/api";
import { _getOptions } from "../../../Helper/helper";

const NOMINEE_OPTIONS = [
    {
        id: 1,
        name: "Yes"
    },
    {
        id: 2,
        name: "No"
    },
]



const NomineeDetails = (props) => {
    const PROGRESS_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS);
    const GENERAL_DATA = useSelector(state => state.Reducer.GENERAL_DATA);

    const nomineeRef = useRef([]);

    const [nominee, setNominee] = useState(NOMINEE_OPTIONS[1].id);

    const [nomineeOptions, setNomineeOptions] = useState([]);
    const [guardianOptions, setGuardianOptions] = useState([]);

    const [apiLoader, setApiLoader] = useState(false);
    const [confirmLoader, setConfirmLoader] = useState(false);

    const [kycUser, setKycUser] = useState(false);

    const [esignModal, setEsignModal] = useState(false);
    const [confirmNominee, setConfirmNominee] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [errors, setErrors] = useState([]);

    const [nomineeList, setNomineeList] = useState([
        {
            name: "",
            dob: "DD-MM-YYYY",
            allocation: 100,
            relation: null,
            guardian_name: "",
            guardian_pan: "",
            guardian_relation: null,
            error: ["", "", "", "", "", "", ""]
        }
    ]);

    useEffect(() => {
        const signup_progress = PROGRESS_DATA
        setKycUser(signup_progress.kyc_data.is_kyc_compliant);
        if (signup_progress.kyc_data.is_kyc_compliant === false) {
            if (signup_progress.step_detail[6].error === true) {
                setErrors(signup_progress.step_detail[6].message);
            }
        }
        if (signup_progress.kyc_data.is_nominee_available === true) {
            setNominee(1);
            const nominee_list = signup_progress.kyc_data.nominee.map((item) => {
                return ({
                    name: item.name,
                    dob: item.dob,
                    allocation: item.allocation,
                    relation: { label: item.relation, value: item.relation_id },
                    guardian_name: item.guardian_name !== null ? item.guardian_name : "",
                    guardian_pan: item.guardian_pan !== null ? item.guardian_pan.toLocaleUpperCase() : "",
                    guardian_relation: item.guardian_relation !== null ? { label: item.guardian_relationship, value: item.guardian_relationship_id } : "",
                    error: ["", "", "", "", "", "", ""]
                })
            });
            setNomineeList(nominee_list);
        }
    }, [PROGRESS_DATA]);

    useEffect(() => {
        // account types options
        const kyc_relationship = _getOptions(GENERAL_DATA.kyc_relationship);
        setNomineeOptions(kyc_relationship);

        const nominee_minor_relation = _getOptions(GENERAL_DATA.nominee_minor_relation);
        setGuardianOptions(nominee_minor_relation);
    }, [GENERAL_DATA]);

    useEffect(() => {
        if (showSuccess === true) {
            const modal = new Modal(document.getElementById("confirm"));
            modal.show();
        }
    }, [showSuccess]);

    useEffect(() => {
        if (esignModal === true) {
            const modal = new Modal(document.getElementById("create-esign"));
            modal.show();
        }
    }, [esignModal]);

    useEffect(() => {
        if (confirmNominee === true) {
            const modal = new Modal(document.getElementById("nominee-confirm"));
            modal.show();
        }
    }, [confirmNominee]);

    function _handleNomineeType(id) {
        setNominee(id);
    }

    // handle add nominee 
    function _handleAddNominee() {
        const nominee_list = [...nomineeList]
        nominee_list.push({
            name: "",
            dob: "DD-MM-YYYY",
            allocation: "",
            relation: null,
            guardian_name: "",
            guardian_pan: "",
            guardian_relation: null,
            error: ["", "", "", "", "", "", ""]
        });
        setNomineeList(nominee_list);
    }

    // handle remove nominee 
    function _handleRemoveNominee(index) {
        const nominee_list = [...nomineeList]
        nominee_list.splice(index, 1)
        setNomineeList(nominee_list);
    }

    // handle nominee values 
    function _handleNominee(item_value, key, index, error_index) {
        const nominee_list = [...nomineeList]
        nominee_list[index][key] = item_value;
        nominee_list[index]['error'][error_index] = "";
        setNomineeList(nominee_list);
    }



    // handle confirm investment
    function _confirmInvestment(status) {
        setShowSuccess(status);
    }

    // handle confirm investment
    function _confirmNominee(status, is_confirmed) {
        setConfirmNominee(status);
        if (is_confirmed === true) {
            if (kycUser === true) {
                _confirmInvestment(true);
            } else {
                _initiateEsign(true);
            }
        }
    }


    // handle esign modal
    function _initiateEsign(status) {
        setEsignModal(status);
    }

    // handle validate nominees
    function _validateNominees() {
        let valid = true;
        const validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN
        let is_scrolled = false;
        if (nominee === 1) {
            const nominee_list = [...nomineeList]
            for (let i = 0; i < nominee_list.length; i++) {

                const element = nominee_list[i];
                const IS_MINOR = differenceInYears(new Date(), new Date(element.dob)) < 18;

                if (element.name === "") {
                    nominee_list[i].error[0] = "Nominee name is required"
                    valid = false
                    if (!is_scrolled) {
                        nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                        is_scrolled = true;
                    }
                }
                if (element.relation === null) {
                    nominee_list[i].error[1] = "Relation is required"
                    valid = false
                    if (!is_scrolled) {
                        nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                        is_scrolled = true;
                    }
                }
                if (element.dob === "DD-MM-YYYY") {
                    nominee_list[i].error[2] = "Date of birth is required"
                    valid = false
                    if (!is_scrolled) {
                        nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                        is_scrolled = true;
                    }
                }
                if (element.allocation === "" || element.allocation < 1) {
                    nominee_list[i].error[3] = "Allocation is required"
                    valid = false
                    if (!is_scrolled) {
                        nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                        is_scrolled = true;
                    }
                }
                if (IS_MINOR === true && element.guardian_name === "") {
                    nominee_list[i].error[4] = "Guardian name is required"
                    valid = false
                    if (!is_scrolled) {
                        nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                        is_scrolled = true;
                    }
                }
                if (IS_MINOR === true && element.guardian_pan === "") {
                    nominee_list[i].error[5] = "Guardian PAN number is required"
                    valid = false
                    if (!is_scrolled) {
                        nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                        is_scrolled = true;
                    }
                }
                if (IS_MINOR === true && element.guardian_pan.toLocaleUpperCase() !== "" && !validate_pan.test(element.guardian_pan.toLocaleUpperCase())) {
                    nominee_list[i].error[5] = "Invalid PAN number"
                    valid = false;
                    if (!is_scrolled) {
                        nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                        is_scrolled = true;
                    }
                }
                if (IS_MINOR === true && element.guardian_relation === null) {
                    nominee_list[i].error[6] = "Choose relation with nominee (guardian relation)"
                    valid = false
                    if (!is_scrolled) {
                        nomineeRef.current[i].scrollIntoView({ block: "start", behavior: "smooth" });
                        is_scrolled = true;
                    }
                }

            }
            setNomineeList(nominee_list);

            if (valid === true) {
                _saveNomineeDetails();
            }
        } else {
            _saveNomineeDetails();
        }
    }

    // API -save nominee
    const _saveNomineeDetails = () => {
        var nominee_data = [];

        setApiLoader(true);
        toast.dismiss();

        const url = "/kyc/confirm-save-nominee";

        if (nominee === 1) {
            nominee_data = nomineeList.map((item) => {
                const MINOR = differenceInYears(new Date(), new Date(item.dob)) < 18;
                return ({
                    name: item.name,
                    relation_id: item.relation.value,
                    dob: item.dob,
                    guardian_name: MINOR === true ? item.guardian_name : null,
                    guardian_relationship_id: MINOR === true ? item.guardian_relation.value : null,
                    guardian_pan: MINOR === true ? item.guardian_pan : null,
                    allocation: item.allocation
                })
            })
        }

        const sum = nomineeList.reduce((a, b) => parseInt(a) + parseInt(b.allocation), 0);
        if (nominee === 1) {
            if (sum !== 100) {
                setApiLoader(false);
                toast.error("Total alllocation should be 100%", {
                    type: "error"
                });
                return false;
            }
        }

        const request = {
            "nominee": nominee_data,
            "is_nominee_available": nominee === 1 ? true : false
        }

        APIService(true, url, request).then((response) => {

            if (response.status_code === 200) {
                _getSignupProgress().then((kyc_response) => {
                    _confirmNominee(true);
                });
            } else {
                _getSignupProgress().then((kyc_response) => {
                    if (kyc_response.kyc_data.is_nominee_available === true) {
                        window.location.reload()
                    } else if (kyc_response.kyc_data.status === "KYC_ESIGN_REQUIRED") {
                        window.location.reload()
                    }
                })
                toast.error(response.message, {
                    type: "error"
                });
            }
            setApiLoader(false);
        });
    }


    // API - add nominee
    const _addNomineeDetails = async () => {
        let status = false;
        toast.dismiss();

        setConfirmLoader(true);

        const url = "/kyc/add-nominee";

        await APIService(false, url).then((response) => {

            if (response.status_code === 200) {
                toast.success(nominee === 1 ? "Nominee added successfully" : "Nominee Opted out successfully", {
                    type: "success"
                });
                _getSignupProgress()
                status = true;

            } else {
                toast.error(response.message, {
                    type: "error"
                });
                status = false;
            }
            setConfirmLoader(false);
        });
        return status;
    }

    return (
        <Fragment>
            <Icon icon="Arrow-Right"
                size={32}
                className="margin-12px-tb rotate-left cursor-pointer"
                onClick={props.navigateBack} />
            <div className="row pt-lg-3 pt-0">
                <div className="col-lg-7">
                    <StepProgress type={2}
                        step={props.completed}
                        stepNumber={kycUser ? 6 : 7}
                        maxStep={kycUser ? 6 : 8} />
                    <h6 className="color-eerie-black e-font-16 e-line-height-32 e-montserrat-semi-bold mb-0 mt-4">Nominee details</h6>

                    {
                        errors.length > 0 &&
                        <KYCErrorCard errors={errors} />
                    }
                    <div className="row">
                        <div className="col-lg-11 ">
                            <div className={`color-vivid-orange e-bg-ivory-bridge my-4 d-flex align-items-start padding-12px-all border-radius-12px e-font-14 e-line-height-20 gap-8px e-montserrat-regular`}>
                                <Icon icon="info"
                                    size={20} />
                                Adding a nominee ensures your assets goes to your chosen person, making things easier when it's needed.
                            </div>
                        </div>
                        <div className="col-lg-11">
                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                Do you want to add a nominee to your investment account?
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup data={NOMINEE_OPTIONS}
                                selected={nominee}
                                handleSelect={_handleNomineeType} />
                            {
                                nominee === 1 &&
                                <Fragment>
                                    {
                                        nomineeList.map((item, index) => {
                                            return (
                                                <div ref={el => nomineeRef.current[index] = el}>
                                                    <NomineeCard allocation={item.allocation}
                                                        dob={item.dob}
                                                        name={item.name}
                                                        error={item.error}
                                                        key={index}
                                                        index={index}
                                                        relation={item.relation}
                                                        guardianName={item.guardian_name}
                                                        guardianPan={item.guardian_pan}
                                                        guardianRelation={item.guardian_relation}
                                                        total={nomineeList.length}
                                                        nomineeOptions={nomineeOptions}
                                                        guardianOptions={guardianOptions}
                                                        handleNominee={(initial_value, key, error_index) => _handleNominee(initial_value, key, index, error_index)}
                                                        removeNominee={() => _handleRemoveNominee(index)} />
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="d-flex align-items-center gap-4px e-bg-light-lavender padding-10px-tb px-2 border-radius-44px e-font-14 e-line-height-18 e-montserrat-medium w-max-content color-rebecca-purple cursor-pointer" onClick={_handleAddNominee}>
                                        <Icon icon="add"
                                            size={24} />
                                        Add another nominee
                                    </div>
                                </Fragment>

                            }
                        </div>

                        <div className="border-top-1px border-bright-gray my-md-4 my-3" />
                        <div className="row">
                            <div className="col-xl-3 col-lg-5 d-lg-block d-none">
                                <PrimaryButton label="Submit"
                                    className="padding-12px-tb w-100"
                                    disabled={apiLoader}
                                    onPress={_validateNominees} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <KYCProgressCard steps={props.investment_steps}
                        completedStep={props.completedStep} />
                    <div className="row justify-content-center">

                        <div className="col-md-6 col-sm-6 col-6  d-lg-none d-block  pt-3">
                            <PrimaryButton label="Submit"
                                className="padding-12px-tb w-100"
                                disabled={apiLoader}
                                onPress={_validateNominees} />
                        </div>
                    </div>
                </div>
            </div>

            {
                showSuccess &&
                <KYCConfirmationModal type={1}
                    label="Dashboard"
                    show_button={true}
                    title="Congratulations"
                    content="Your account has been successfully created. Explore the funds, goals, and start your first investment now."
                    closeModal={() => _confirmInvestment(false)}
                    handleNavigate={props.handleNavigate} />
            }

            {
                esignModal === true &&
                <EsignCreateModal
                    closeModal={() => {
                        _initiateEsign(false);
                    }}
                    submitInvestment={() => {
                        _initiateEsign(false);
                        _confirmInvestment(true);
                    }}
                    reSubmit={() => {
                        _initiateEsign(false);
                        const modalBackdrop = document.querySelector('.modal-backdrop');
                        if (modalBackdrop) {
                            modalBackdrop.classList.remove('modal-backdrop');
                            document.body.removeAttribute('style')
                        }
                    }}
                />
            }

            {
                confirmNominee === true &&
                <NomineeConfirmationModal loader={confirmLoader}
                    closeModal={() => _confirmNominee(false)}
                    confirmNominee={_addNomineeDetails}
                    nomineeConfirmed={() => {
                        _confirmNominee(false, true);
                        const modalBackdrop = document.querySelector('.modal-backdrop');
                        if (modalBackdrop) {
                            modalBackdrop.classList.remove('modal-backdrop');
                            document.body.removeAttribute('style')
                        }
                    }} />
            }

        </Fragment >
    )
}
export default NomineeDetails